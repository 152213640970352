.focus-edit-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  background-image: url("/assets/svg/bg/company-focus-edit-form.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 2px) 0;
  background-size: auto calc(100% - 2px);

  &__item-container {
    flex: 1 0 420px;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 16px;
    min-height: 28px;

    &__status {
      .cc-select {
        width: 100%;
      }
    }

    &__priority {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &__template-link {
      .cc-input__transparent {
        font-size: $fs-text-lg;
        line-height: $lh-text-lg;
        font-weight: $fw-regular;
        flex: 1 1;
        color: $clr-text-labels-3;
        text-decoration: none;

        &.has-value {
          color: $lnk-highlight;
          text-decoration: underline;
          font-weight: $fw-bold;
        }
      }

      i {
        color: $clr-text-labels-3;
        opacity: 0.7;
        cursor: pointer;
      }
    }

    &__content {
      flex: 0 0 200px;
      width: 200px;
    }
  }

  &__label {
    display: flex;
    align-items: baseline;
    gap: 6px;
    flex: 0 0 165px;

    &__icon {
      width: 20px;
      color: $clr-primary;
      opacity: 0.5;
      font-size: $fs-text-md;
      line-height: $lh-text-md;
    }

    .person {
      width: 15px;
    }

    &__text {
      font-size: $fs-text-lg;
      line-height: $lh-text-lg;
      color: $clr-gray-icon;
    }
  }

  &__history {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 20px 0 20px 20px;

    &__item-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: $clr-text-labels-5;
      font-size: $fs-text-md;
      line-height: $lh-text-md;
      overflow: auto;
      padding-right: 20px;
    }
  }
}

.cc-select__values.cc-select .kiss-select__dropdown {
  width: max-content !important;
}

.cc-select {
  .add-theme-section {
    padding: 6px 20px;
    position: sticky;
    bottom: 0;
    background: $clr-white;
    border-top: 1px solid $clr-border-3;
    font-size: $fs-text-sm;

    &:hover {
      background: $clr-secondary-50;
    }

    i {
      font-size: $fs-text-xs;
    }
  }
}
