.company-info {
  &__title {
    font-size: $fs-h2;
    line-height: $lh-h2;
    font-weight: $fw-extra-bold;
    color: $clr-primary;
  }

  &__created {
    font-size: $fs-h4;
    line-height: $lh-h4;
    font-weight: $fw-bold;
    color: $clr-text-table;
  }
}
