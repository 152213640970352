.company-dash-users {
  display: flex;
  flex-direction: column;
  .users-section {
    display: flex;
    flex-wrap: wrap;

    &__title {
      color: $clr-text-labels-3;
      font-weight: $fw-bold;
      font-size: $fs-text-md;
      line-height: $lh-text-md;
      margin-right: 8px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__user {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;

      &__name {
        color: $clr-text;
        position: relative;
      }

      &__edit {
        font-size: $fs-text-sm;
        color: $clr-text-labels-3;
        margin-left: 5px;
        visibility: hidden;
      }

      &__status {
        position: absolute;
        right: 0px;
        bottom: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        cursor: pointer;

        .ACTIVE {
          background: $clr-secondary;
        }

        .PENDING {
          outline: 2px solid $clr-primary;
          outline-offset: -2px;
        }

        .IN_ACTIVE {
          outline: 1px solid $clr-text-table;
          outline-offset: -1px;
        }

        &__name {
          display: none;
          position: absolute;
          background: $clr-white;
          border-radius: 4px;
          box-shadow: -4px 7px 8.8px 0px #31476533;
          z-index: 2;
          padding: 0 6px;
          color: $clr-text;
          font-size: $fs-text-xs;
          line-height: $lh-text-xs;
          font-weight: $fw-bold;
          top: 4px;
          left: 4px;
        }

        &:hover {
          .users-section__user__status__name {
            display: block;
          }
        }
      }

      &:hover {
        .users-section__user__edit {
          visibility: visible;
        }
      }
    }
  }
}
