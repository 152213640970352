.kiss-toggle-switch-default {
  &.checked {
    .kiss-toggle-switch__slider {
      background: $clr-secondary-75;

      &::before {
        background: $clr-secondary-500;
      }
    }
  }
}
