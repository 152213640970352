.company-kick-off-docs {
  .cc-select {
    width: 320px;
  }

  .file-upload {
    width: 100%;
    .form-item-container {
      width: 100%;
      margin-bottom: 8px !important;
      .textarea-container {
        border-radius: 20px !important;
        position: relative;

        .icon-placeholder {
          position: absolute;
          top: 4px;
          left: 117px;
          font-size: $fs-text-sm;
          color: $clr-text-table;
        }

        .char-counter {
          position: absolute;
          bottom: 12px;
          right: 12px;
          font-size: $fs-text-xs;
          line-height: $lh-text-xs;
          color: $clr-text-labels-2;
        }

        .cc-input.textarea {
          resize: none;
          height: 96px;
        }
      }
    }
  }

  .file-preview {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .file-item {
      display: flex;

      align-items: center;
      margin-bottom: 10px;
      gap: 3px;

      p {
        margin: 0;
        font-size: $fs-text-sm;
        line-height: $lh-text-sm;
        font-weight: $fw-regular;
        color: $clr-text-labels-3;
      }

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      button {
        border: none;
        background: none;
        font-size: $fs-text-sm;
        line-height: $lh-text-sm;
        font-weight: $fw-bold;
        color: $clr-text-labels-4;
        cursor: pointer;
      }
    }
  }
}

.dragover {
  background-color: #f0f8ff;
  border-width: 2px;
  border-style: dashed;
}
