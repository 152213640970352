.cc-list {
  padding: 32px;

  &__title {
    margin-bottom: 32px;
    font-size: $fs-h2;
    line-height: $lh-h2;
    font-weight: $fw-extra-bold;
    color: $clr-primary;
  }

  &__search-filters {
    display: flex;
    align-items: center;
  }

  &__header {
    margin-bottom: 32px;

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search-input {
      margin-right: 18px;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;

    .wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;

      .filters-group {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;

        p {
          color: $clr-text;
          font-size: $fs-text-sm;
          font-weight: $fw-bold;
          line-height: $lh-text-sm;
        }

        .tag {
          color: $clr-secondary-dark;
          background: $clr-cyan-tag;
          font-size: $fs-text-sm;
          font-weight: $fw-bold;
          padding: 2px 4px 2px 4px;
          width: auto;
          height: 24px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          gap: 5px;
          border: none;
          cursor: pointer;
          line-height: $lh-text-sm;

          i {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $fs-text-xs;
          }
        }
      }
    }
  }

  &__list {
    padding: 0 0 5px 0;
    background-color: transparent;

    &-table {
      width: 100%;
    }
  }

  &__paginator {
    margin-top: 32px;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 350px;

    &__text {
      font-weight: $fw-bold;
      font-size: $fs-h1;
      line-height: $lh-h1;
      color: $clr-text-labels-4;
      text-align: center;
    }
  }
}
