.company-dashboard-goals-flyout {
  textarea {
    resize: none;
    height: 142px;
    border-radius: 20px !important;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cc-btn__icon {
      color: $support-error;
      font-weight: $fw-black !important;
      cursor: pointer;
      width: 15%;
      gap: 10px;
    }

    .cc-flyout__action-buttons {
      justify-content: flex-end;
    }
  }
}
