.company-dash-focus-areas-themes {
  width: calc(50% - 12px);
  min-width: 365px;

  @media (max-width: 76.7em) {
    width: 100%;
  }

  &__container {
    height: 100%;
    padding: 20px;
    margin-bottom: 0;

    .cc-card__header {
      margin-bottom: 24px;
    }
  }

  &__content-wrapper {
    height: 100%;
    max-height: 500px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px 24px;
    overflow: auto;

    &.empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__header {
    background: $lnk-highlight-100;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-info {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    font-weight: $fw-bold;
    color: $clr-text;
  }

  &__header-status-select {
    width: 120px;
  }

  &__date,
  &__time {
    display: flex;
    align-items: center;

    i {
      margin-right: 4px;
    }
  }

  &__title {
    font-size: $fs-text-md;
    line-height: $lh-text-md;
    font-weight: $fw-bold;
    color: $clr-text;
  }

  &__item-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 8px;

    &__title {
      font-size: $fs-text-sm;
      line-height: $lh-text-sm;
      color: $clr-text-table;
    }

    &__text {
      font-size: $fs-text-sm;
      line-height: $lh-text-sm;
      color: $clr-primary-500;
    }
  }
}
