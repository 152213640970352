/* KISS NAVIGATION SIDEBAR */

.use-default-color {
  .main-nav-sidebar {
    background: $clr-primary-400;
  }
}

.use-company-color {
  .main-nav-sidebar {
    background: $clr-company-base;
  }
}

.main-nav-sidebar {
  width: 290px !important;
  min-width: 290px !important;
  max-width: 290px !important;
  overflow: visible;

  @include kiss-media("sm") {
    width: 16.25rem;
    max-width: 16.25rem;
  }

  // ACTIVE SIDEBAR
  .kiss-navigation-sidebar {
    overflow: hidden;

    .kiss-navigation {
      padding: 24px 0 24px 0;
      flex: 1 1;

      // ACTIVE GROUP
      .kiss-navigation-group {
        .nav-group-title {
          color: $clr-nav-group-title;
          font-size: $fs-text-xs;
          line-height: $lh-text-xs;
          font-weight: 500;
          margin: 0 32px 4px 32px;
          padding: 0;
          min-height: auto;

          &--lg {
            margin-bottom: 13px;
          }
        }

        .nav-seperator {
          height: 1px;
          opacity: 0.45;
          background: radial-gradient(50% 50% at 50% 50%, $clr-primary-h2 0%, transparent 100%);
          margin: 12px 16px;

          &::before {
            border: none;
          }
        }
      }

      // ACTIVE ITEM
      .kiss-navigation-item {
        display: block;
        margin: 0 24px;

        .nav-link {
          min-height: 40px;
          border-radius: 8px;
          font-weight: 400;
          padding: 8px 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          .nav-icon {
            margin: 0;
            width: 20px;
            height: 20px;
            flex: 0 0 20px;
          }

          .nav-title {
            padding: 0 16px;
          }
        }

        &.active {
          .nav-link {
            background-color: $clr-nav-link;
          }
        }
      }
    }
  }

  .nav-expand-btn {
    background: $clr-nav-link;
    width: 24px;
    height: 24px;
    font-size: 16px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $clr-white;
    outline: none;
    cursor: pointer;
    top: 38px;
    right: 28px;
  }

  // FOLDED SIDEBAR
  &.folded {
    width: 69px !important;
    min-width: 69px !important;
    max-width: 69px !important;

    .kiss-navigation-sidebar {
      .kiss-navigation {
        // FOLDED GROUP
        .kiss-navigation-group {
          .nav-group-title {
            margin: 0 0px 4px 0px;
            text-align: center;
          }
        }

        // FOLDED ITEM
        .kiss-navigation-item {
          margin: 0 14.5px;
        }
      }
    }

    .nav-expand-btn {
      position: absolute;
      left: 72px;
      right: unset;
      background: $clr-primary-h2;
    }
  }
}
/* !KISS NAVIGATION SIDEBAR */
