.company-dash-card {
  margin-bottom: 24px;

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      font-size: $fs-h3;
      line-height: $lh-h3;
      font-weight: $fw-bold;
      margin-top: 16px;
      color: $clr-text;
    }

    &__text {
      font-size: $fs-h4;
      line-height: $lh-h4;
      color: $clr-text-table;
      font-weight: 500;
      margin: 16px 0;
    }

    &__image {
      width: 100px;
      height: auto;
    }
  }

  &__loading {
    font-size: 55px;
    color: $clr-text-table;
    min-height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
