.company-kick-off {
  width: 100%;
  height: 100%;
  padding: 0;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 48px;
    width: 100%;

    &-step-description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .step-name {
        margin: 0;
        font-size: $fs-text-md;
        line-height: $lh-text-md;
        font-weight: $fw-regular;
        color: $clr-primary-h2;
        text-transform: uppercase;
      }
      .step-title {
        margin: 0 0 16px 0;
        font-size: $fs-h2;
        line-height: $lh-h2;
        font-weight: $fw-bold;
        color: $clr-primary;
      }

      .step-description {
        margin: 0;
        font-size: $fs-h4;
        line-height: $lh-h4;
        font-weight: $fw-regular;
        color: #607188;
      }
    }

    &-step-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .kick-off {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 24px;
        width: 100%;
        gap: 24px;

        &__title {
          margin: 0;
          font-size: $fs-h3;
          line-height: $lh-h3;
          font-weight: $fw-bold;
          color: $clr-primary;
        }

        &__options {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 24px;
          width: 100%;

          .form-item-container {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  // &__select {
  //   width: 220px;
  // }

  // Remove
  .cc-radio {
    padding-left: 0 !important;

    &__text {
      font-size: $fs-text-lg;
      line-height: $lh-text-lg;
      color: $clr-text;
    }
  }
}
