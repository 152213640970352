.kiss-sort-tag {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
  position: relative;
  width: 15px;
  height: 15px;

  &__arrow {
    $this: &;
    border: solid #b4b4b4;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    cursor: pointer;

    &--asc {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    &--desc {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    &--asc,
    &--desc {
      &:hover {
        border-color: rgb(92, 92, 92);
      }

      &--active {
        border-color: rgb(58, 58, 58);
      }
    }
  }
}
