.cc-widget-inline-wrapper {
  display: flex;
  gap: 24px;

  > * {
    width: 50%;
  }

  @include media($from: 0, $until: "md") {
    flex-direction: column;
    > * {
      width: 100%;
    }
  }

  &--margin-bottom {
    margin-bottom: 24px;
  }
}

.cc-widget {
  padding: 20px;
  border: 1px solid $clr-border-3;
  border-radius: 20px;
  background-color: white;

  ::-webkit-scrollbar-thumb {
    background-color: $clr-widget-scrollbar !important;
  }

  &__header {
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: baseline;
    font-size: $fs-h3;
    font-weight: 700;
    line-height: $lh-h3;
    color: $clr-primary;

    &-icon {
      font-size: $fs-h4;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__content {
    padding-bottom: 5px;
    margin-bottom: 7px;
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__view-all-btn {
    width: fit-content;
    padding: 0 4px;
    margin-left: auto;
    font-size: $fs-text-md;
    color: $clr-text-labels-5;
    text-decoration: underline;
    cursor: pointer;
  }

  &__action-buttons {
    display: flex;
    gap: 16px;
  }

  &__loading {
    font-size: 55px;
    color: $clr-text-table;
    min-height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
