.supporting-document {
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    gap: 24px;
  }

  &__grid-item {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid $clr-border-3;
    border-radius: 16px;
    overflow: hidden;

    &--clickable {
      cursor: pointer;
    }

    &__actions {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      color: $clr-text-table;
      padding: 10px 14px;

      i {
        font-size: 18px;
        line-height: 18px;
      }
    }

    &.active-preview {
      outline: 2px solid #5cc2c2;
      outline-offset: -2px;
    }
  }

  &__file {
    display: flex;
    flex-direction: column;
    height: 110px;

    &__img {
      flex: 1 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 45px;
      color: $clr-primary-h2;
    }

    &__name {
      font-size: $fs-text-sm;
      line-height: $lh-text-sm;
      font-weight: $fw-bold;
      color: $clr-primary;
      padding: 8px 11px;
      background: $clr-secondary-50;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__folder {
    display: flex;
    flex-direction: column;
    height: 110px;
    padding: 16px;
    background: $bg-highlight-50;

    .supporting-document__grid-item__actions {
      color: $clr-primary-h2;
    }

    &__icon {
      color: $clr-primary-h2;
      font-size: $fs-h3;
    }

    &__name {
      font-size: $fs-text-lg;
      line-height: $fs-text-lg;
      font-weight: $fw-bold;
      color: $clr-primary;
      padding: 8px 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__count {
      text-align: right;
      color: $clr-text-labels-2;
      font-size: $fs-text-sm;
      line-height: $lh-text-sm;
    }
  }
}
