.company-events-kick-off {
  &__container {
    overflow: auto;
    display: flex;
  }

  &__table {
    min-width: 1300px;

    .kiss-column {
      &-name {
        font-weight: 400 !important;
      }

      &-status {
        flex-basis: 105px !important;
        max-width: 105px;
        width: 100%;

        .text-ellipsis {
          max-width: 105px !important;
        }
      }
    }

    .kiss-row {
      &.active-preview {
        outline: 2px solid $clr-secondary;
        outline-offset: -2px;
      }
    }
  }
}
