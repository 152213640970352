.status-text-wrapper {
  color: $clr-text-lt;
  border: 2px solid $clr-text-lt;
  background: $clr-border-2;

  &--thin {
    border-width: 1px;
  }

  &.COMPLETED {
    color: $clr-secondary-dark;
    background-color: $clr-secondary-50;
    border-color: $clr-secondary-dark;
  }

  &.PLANNED,
  &.SCHEDULED,
  &.IN_PROGRESS {
    color: $clr-primary;
    background-color: $clr-primary-50;
    border-color: $clr-primary;
  }

  &.OVERDUE {
    color: $support-error;
    background-color: $lnk-highlight-50;
    border-color: $support-error;
  }

  &.NOT_STARTED {
    color: #333333;
    background-color: #f0f0f0;
    border-color: #333333;
  }

  &.SCHEDULE_LATER {
    color: $clr-text-lt;
    background-color: $clr-border-2;
    border-color: $clr-text-lt;
  }

  &.ACTIVE {
    color: $clr-secondary-dark;
    border-color: $clr-secondary-dark;
    background-color: $clr-secondary-50;
  }

  &.PENDING {
    color: $clr-text-table;
    border-color: $clr-text-table;
    background-color: transparent;
  }

  &.IN_ACTIVE {
    color: $clr-primary;
    border-color: $clr-primary;
    background-color: $clr-primary-50;
  }
}
