app-search {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  min-height: 32px;
  height: 100%;
  background: #ffffff;
  border-radius: 50px;
  width: 250px;
  border: 1px solid $clr-border-3;

  //   &:focus-within {
  //     outline: 1px solid $clr-border-3;
  //     border-color: $clr-border-3;
  //   }

  .search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr-text-lt;
    width: 16px;
    height: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    margin-right: 6px;
    svg {
      width: 16px;
      height: 16px;
      display: block;
      flex: 0 0 auto;
    }
  }

  .close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr-text-lt;
    width: 16px;
    height: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    svg {
      width: 10px;
      height: 10px;
      display: block;
      flex: 0 0 auto;
    }
  }

  input {
    border: none;
    background: transparent;
    height: auto;
    height: 100%;
    flex: 1 1;
    outline: none;
    width: 100%;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $clr-text-lt;
  }
}
