/* KISS-DATEPICKER-TIME */
.cc-datepicker-time .kiss-datepicker {
  &__dropdown {
    flex-direction: row;
    align-items: center;
    padding: 8px 32px;
    border-radius: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__time {
    min-width: 0;
    margin-right: 24px;

    &--inbetween {
      font-weight: 400;
      font-size: 24px;
      line-height: 44px;
      color: $clr-primary;
    }

    &__container {
      &__item {
        gap: 8px;
      }
    }

    &__item {
      width: 40px;

      &--up,
      &--down {
        color: $clr-text-3;
        font-weight: 900;
        font-size: 15px;
        line-height: 15px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      &__input {
        border: none;
        padding: 0.2em;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  &__btn {
    border-radius: 0.2em;
    padding: 0 0.5em;
    height: unset;

    font-weight: 500;

    line-height: 22px;
    font-size: 0.875rem;

    &:hover {
      background: $clr-secondary-50;
      color: initial;
    }

    &--save {
      border-radius: 10px;
      padding: 3px 15px;
      font-size: $fs-text-lg;
      line-height: $lh-text-lg;
      font-weight: 700;
      color: white;
      background-color: $clr-secondary;

      &:hover {
        color: white;
        background-color: $clr-secondary-400;
      }
    }
  }
}
/*! KISS-DATEPICKER-TIME */
