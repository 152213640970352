/*
Usage
   <label class="cc-radio">
        <input type="radio" class="cc-radio__input" />
        <span class="cc-radio__checkmark">
            <span class="cc-radio__checkmark__inner"></span>
        </span>
        <span class="cc-radio__text">Four</span>
    </label>
*/

.cc-radio {
  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;

  .cc-radio__text {
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    color: $clr-text;
  }

  .cc-radio__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:disabled {
      & ~ .cc-radio__text {
        color: $clr-text-table;
      }

      & ~ .cc-radio__checkmark {
        outline-color: $clr-border-3;
      }
    }

    &:checked {
      & ~ .cc-radio__checkmark {
        outline-color: $clr-secondary-500;
        background: $clr-secondary-500;

        .cc-radio__checkmark__inner {
          display: block;
        }
      }
    }
  }

  .cc-radio__checkmark {
    height: 14px;
    width: 14px;
    outline: 1px solid $clr-secondary-500;
    border-radius: 50%;
    margin: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    .cc-radio__checkmark__inner {
      display: none;
      position: absolute;
      outline: 3px solid $clr-white;
      width: 6px;
      height: 6px;
      background: transparent;
      border-radius: 50%;
    }
  }

  &:hover {
    .cc-radio__checkmark {
      outline-color: $clr-secondary-300;
    }
  }
}
