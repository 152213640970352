$max-width-breakpoint: 120em;

$font-family: "Texta", sans-serif;
$base-url: "/assets/" !default;
$clr-widget-scrollbar: #ecf0f5;

// main
$clr-primary: #4a4091;
$clr-primary-50: #edecf4;
$clr-primary-75: #b5b1d2;
$clr-primary-100: #9690bf;
$clr-primary-200: #6960a4;
$clr-primary-300: #4a4091;
$clr-primary-400: #342d66;
$clr-primary-500: #2d2758;
$clr-primary-h2: #b2a2d0;

// secondary
$clr-secondary: #5cc2c2;
$clr-secondary-dark: #387676;
$clr-secondary-50: #eff9f9;
$clr-secondary-75: #bce6e6;
$clr-secondary-100: #a0dcdc;
$clr-secondary-200: #78cccc;
$clr-secondary-300: #5cc2c2;
$clr-secondary-400: #408888;
$clr-secondary-500: #387676;

//random
$clr-text: #2d2758;
$clr-text-2: #27303a;
$clr-text-3: #2f3f53;
$clr-text-4: #314765;
$clr-text-lt: #5d597e;
$clr-text-table: #817d9b;
$clr-text-labels: #a7a4b9;
$clr-text-labels-2: #717394;
$clr-text-labels-3: #8391a3;
$clr-text-labels-4: #607188;
$clr-text-labels-5: #828d98;
$clr-border-1: #cbc9d5;
$clr-border-2: #e4e3e9;
$clr-border-3: #ccd1d9;
$clr-white: #ffffff;
$clr-text-basic: #000000a6;
$clr-nav-link: #ffffff4d;
$clr-nav-group-title: #ffffff73;
$clr-black: #000000;
$clr-gray-icon: #828d98;
$clr-cyan-tag: #d6f0f0;
$clr-menthol: #bdeda9;
$clr-sky-blue: #a3e0ed;
$clr-teal-green: #00675b;

//bg highlight
$bg-light: #fbfbfc;
$bg-white: #ffffff;
$bg-highlight: #b3a3d1;
$bg-highlight-50: #f7f6fa;
$bg-highlight-75-15: #e0d9ec26;
$bg-highlight-75-50: #e0d9ec80;
$bg-highlight-75: #e0d9ec;
$bg-highlight-100: #d3cae4;
$bg-highlight-200: #c0b3d9;
$bg-highlight-300: #b3a3d1;
$bg-highlight-400: #7d7292;
$bg-highlight-500: #6d637f;
$bg-highlight-2: #716c94;

$bg-gray: #a8b2be;
$bg-gray-2: #e4e7eb;
$bg-gray-3: #f7f8f9;

$lnk-highlight: #de5996;
$lnk-highlight-50: #fceef5;
$lnk-highlight-75: #f1bbd4;
$lnk-highlight-100: #ec9fc2;
$lnk-highlight-200: #e475a8;
$lnk-highlight-300: #de5996;
$lnk-highlight-400: #9b3e69;
$lnk-highlight-500: #87365c;

//additional system colors
$support-error: #c91d25;
$support-error-50: #c91d254d;
$support-error-3: #f7dddf;
$support-success: #73b234;
$support-success-2: #73b23426;
$support-success-3: #eaf4e1;
$support-warning: #e8a530;
$support-warning-2: #e8a53026;
$support-warning-3: #fcf2e0;
$support-dark: #1c1c1c;
$support-light: #f0f0f0;
$support-black: 000000a6;
$support-icon: #a8b2be;
$support-info-2: #fbf9fc;

//company colors
$clr-company-base: var(--clr-company-base, $clr-primary-400);
$clr-company-highlight: var(--clr-company-highlight, $bg-highlight-2);
$clr-company-text: var(--clr-company-text, $clr-white);
$clr-company-button: var(--clr-company-button, $clr-teal-green);
$clr-company-button-highlight: var(--clr-company-button-highlight);

//font sizes
$fs-xxl: 64px;
$fs-xl-2: 52px;
$fs-xl: 42px;
$fs-h1: 40px;
$fs-h2: 32px;
$fs-h3: 24px;
$fs-h4: 20px;
$fs-text-lg: 18px;
$fs-text-md: 16px;
$fs-text-sm: 14px;
$fs-text-xs: 12px;

//line height
$lh-xxl: 89.6px;
$lh-xl-2: 72px;
$lh-xl: 58.8px;
$lh-h1: 48px;
$lh-h2: 40px;
$lh-h3: 32px;
$lh-h4: 28px;
$lh-text-lg: 28px;
$lh-text-md: 24px;
$lh-text-sm: 20px;
$lh-text-xs: 18px;

//font weights
$fw-thin: 100;
$fw-extra-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-black: 900;

//boxshadows
$box-shadow-1: 0px 3.2px 7.2px 0px #38767626;
