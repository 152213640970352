.content-hover-info {
  $content-size: 12px;
  position: relative;
  width: $content-size;
  height: $content-size;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    color: $clr-primary-500;
    cursor: pointer;
    font-size: $content-size;

    &:hover {
      @extend .fa-solid;

      & + .content-hover-info__text-container {
        display: block;
      }
    }

    &--solid-on-hover {
      &:hover {
        @extend .fa-solid;
      }
    }
  }
}
