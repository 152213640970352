.edit-team-flyout {
  .company-team-form__row {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 32px;
  }

  .company-team-form__header {
    margin-bottom: 8px;
  }

  .company-team-form__group-wrapper {
    .card-panel {
      width: 100%;
    }
  }

  .form-item-container {
    margin-bottom: 0;
  }
}
