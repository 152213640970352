.company-onboarding-teams {
  @import "../../components/forms/company-team-form";

  .add-btn {
    margin-right: auto;
  }

  .company-team-form {
    padding: 8px 12px;
    background: $bg-gray-3;
    border-radius: 16px;
    border: 1px solid $clr-border-3;
    width: 100%;
    margin-bottom: 24px;
  }
  .company-onboarding__form-content {
    gap: 24px !important;
  }

  .company-onboarding__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 0 !important;

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
