.kiss-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;

  &__item {
    display: flex;
    align-items: center;
    flex: 1 1;
    overflow: auto;
  }

  &__user {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }

  &__action-btn {
    padding: 8px;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__user-btn {
    margin: 0 16px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;

    &__name {
      padding: 0 9px;
      display: none;
    }

    svg {
      display: none;
    }

    @include kiss-media('sm') {
      &__name,
      svg {
        display: block;
      }
    }
  }
}

.kiss-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 256px;

  .kiss-menu-icon {
    margin: 0 3px;
    color: var(--tertiary-bg, #a9915d);
  }

  span {
    padding-left: 16px;
  }
}
