.company-events-check-in-view-flyout {
  .check-in-name {
    color: $clr-text;
    font-size: $fs-h3;
    line-height: $lh-h3;
    font-weight: $fw-bold;
    margin-bottom: 24px;
    padding: 0 16px;
  }
  .check-in__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    padding: 0 16px;

    .detail {
      display: flex;
      align-items: center;
      gap: 8px;

      .label {
        width: 90px;
        color: $clr-text-table;
        font-size: $fs-text-md;
        line-height: $lh-text-md;
        font-weight: $fw-bold;
        min-width: 90px;
      }

      .value {
        color: $clr-text-lt;
        font-size: $fs-text-sm;
        line-height: $lh-text-sm;
        font-weight: $fw-regular;
      }
    }

    &--teams {
      .value {
        font-size: $fs-text-md !important;
        line-height: $lh-text-md !important;
        font-weight: $fw-bold !important;
        color: $clr-text !important;
      }
    }
    &--focus-areas {
      .value {
        font-size: $fs-text-md !important;
        line-height: $lh-text-md !important;
        font-weight: $fw-bold !important;
        color: $clr-text !important;
      }
    }
    &--links {
      align-items: flex-start !important;
      flex-wrap: wrap;
      .links {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;

        .value {
          font-size: $fs-text-xs;
          color: $lnk-highlight;
          line-height: $lh-text-xs;
          font-weight: $fw-bold;
          cursor: pointer;
        }
      }
    }

    &--benefits,
    &--learning {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 8px;

      .value {
        font-size: $fs-text-md !important;
        line-height: $lh-text-md !important;
        font-weight: $fw-bold !important;
        color: $clr-text !important;
        word-break: break-all;
      }
    }

    &--status {
      .text-ellipsis {
        text-align: center;
        padding: 2px 12px;
        border-radius: 200px;
        line-height: 20px;
      }

      app-status-select {
        width: 100%;
      }
    }
  }
}
