.kiss-search {
  display: flex;
  padding: 9px 0;
  align-items: center;
  height: 100%;

  &__icon-btn {
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 11px;
    flex: 0 0 18px;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__close-btn {
    cursor: pointer;
    flex: 0 0 12px;
    svg {
      width: 12px;
      height: 12px;
    }
  }

  input {
    border: none;
    background: transparent;
    height: auto;
    height: 100%;
    flex: 1 1;
    outline: none;
    width: 100%;
  }
}
