.cc-status-select.kiss-select {
  width: 100%;
  border-radius: 30px;
  overflow: hidden;

  &.kiss-disabled {
    opacity: 0.6;
  }

  .kiss-select-container {
    padding: 2px 10px;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    user-select: none;
  }

  &.scheduled {
    color: $clr-primary;
    border: 1px solid $clr-primary;
    background-color: $clr-primary-50;

    .kiss-select__icon {
      color: $clr-primary;
    }
  }

  &.overdue {
    color: $support-error;
    background-color: $lnk-highlight-50;
    border: 1px solid $support-error;

    .kiss-select__icon {
      color: $support-error;
    }
  }

  &.completed {
    color: $clr-secondary-dark;
    border: 1px solid $clr-secondary-dark;
    background-color: $clr-secondary-50;

    .kiss-select__icon {
      color: $clr-secondary-dark;
    }
  }
}

.cc-status-select .kiss-select {
  &__placeholder {
    color: $clr-text-table;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr-text-labels-2;
  }

  &__post-icon {
    margin: 0 0 0 4px;
  }

  &__pre-icon {
    margin: 0 4px 0 0;
  }

  &__dropdown {
    border-radius: 16px;
    box-shadow: $box-shadow-1;
    padding: 8px 0;

    .kiss-checkbox {
      margin-right: 12px;
    }
  }

  &__dropdown-header {
    .kiss-checkbox {
      border-bottom: 2px solid #eceae8;
      margin-bottom: 0px;
      width: 100%;
      padding: 12px 8px 12px 21px !important;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      &__label {
        width: 100%;
      }
      &__content {
        margin-left: 12px !important;
      }
      &--selected {
        background: $clr-secondary-50;
        color: $clr-secondary-dark;
      }

      &:hover:not(&--selected) {
        background: $clr-secondary-50;
        color: initial;
      }
    }
  }

  &__label{
    &-container {
      text-align: center;
    }
  }

  &__option {
    padding: 6px 20px;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    outline: none;

    &__checkbox {
      @extend .cc-checkbox;
      margin-right: 10px;
    }

    &--selected {
      background: $clr-secondary-50;
      color: $clr-secondary-dark;
    }

    &:hover:not(&--selected) {
      background: $clr-secondary-50;
      color: initial;
    }
  }
}

.cc-status-select__subtitle {
  color: $clr-text;
  font-weight: $fw-bold;
  font-size: $fs-text-md;
  line-height: $lh-text-md;
  padding-left: 12px;
}