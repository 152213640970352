.company-onboarding-success {
  background: url("/assets/svg/bg/success-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  height: 655px;

  @media screen and (max-width: 1300px) {
    height: 400px;
  }
}
