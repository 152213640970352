.kiss-toggle-switch {
  font-size: 20px;

  &__slider {
    background: $bg-gray-2;

    &::before {
      background: $bg-gray;
    }
  }

  &.checked {
    .kiss-toggle-switch__slider {
      background: $clr-company-button-highlight;

      &::before {
        background: $clr-company-button;
      }
    }
  }

  &.disabled {
    .kiss-toggle-switch__slider {
      background: #e8f6f6;

      &::before {
        background: #bacfcf;
      }
    }
  }
}
