.cc-filters-section {
  &__wrap {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .kiss-select__label {
    color: $clr-text !important;
    font-weight: 700 !important;
    font-size: $fs-text-sm !important;
  }

  &__sort {
    color: $clr-text-lt;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    min-width: 60px;
    padding: 0 0 0 8px;
    background: none;
    border: none;
    gap: 8px;

    .kiss-select {
      &__post-icon {
        display: none;
      }

      &__placeholder {
        font-size: $fs-text-sm;
      }
    }
  }

  &__button {
    color: $clr-text-lt;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: $fs-text-sm;
    height: 32px;
    min-width: 60px;
    padding: 0 0 0 8px;
    background: none;
    border: none;
    gap: 8px;

    .filters-section__icon {
      max-width: 18px;
      max-height: 18px;
      height: 18px;
      display: flex;
      align-items: center;
    }

    .active-filters-text {
      color: $clr-text;
      font-weight: 700;
    }
  }

  &__checkbox {
    margin-left: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: $clr-text-lt;

    .kiss-checkbox__checkmark {
      background-color: $clr-text-lt;

      &-container {
        width: 13px;
        height: 13px;
        outline: 2px solid $clr-text-lt;
        border-radius: 2px;
      }
    }
    .kiss-checkbox__label {
      display: flex;
      gap: 8px;
      font-size: $fs-text-sm;
      font-weight: $fw-regular;
      align-items: center;
      justify-content: center;
    }
  }

  &__icon {
    color: $clr-text-lt;
  }
}

.cc-sort-dropdown {
  transform: translateX(-20px);
  opacity: 100% !important;

  .kiss-select {
    &__option-container {
      height: auto;
      max-height: fit-content;
    }

    &__dropdown {
      width: 250px;
      border-radius: 16px;
      border: 1px solid $clr-border-3;
      background-color: $clr-primary-50;
      color: $clr-text;
    }

    &__option {
      display: flex;
      justify-content: space-between;
      &:hover {
        background-color: #b3a3d133 !important;
        color: $clr-secondary-dark;
      }

      &--selected {
        background: #b3a3d133 !important;
        color: $clr-secondary-dark;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
