/* KISS DATEPICKER*/
.cc-datepicker.kiss-datepicker {
  border: 1px solid $clr-border-3;
  border-radius: 30px;
  color: $clr-text;
  overflow: hidden;

  &.kiss-disabled {
    opacity: 0.6;
  }

  .kiss-datepicker-container {
    padding: 6px 12px;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    background: $bg-white;
    user-select: none;
  }
}

.cc-datepicker .kiss-datepicker {
  &__dropdown {
    border-radius: 20px;
    box-shadow: $box-shadow-1;
    padding: 16px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr-text-labels-2;
  }

  &__post-icon {
    margin: 0 0 0 4px;
  }

  &__pre-icon {
    margin: 0 4px 0 0;
  }

  &__btn {
    border-radius: 0.2em;
    padding: 0;
    height: 2.25rem;

    font-weight: 500;
    color: $clr-text;
    line-height: 22px;
    font-size: 0.875rem;

    &:hover {
      background: $clr-secondary-50;
      color: initial;
    }
  }

  &__view-info-date {
    color: $clr-text;

    &__month,
    &__year {
      font-size: 1rem;
      padding: 0.2em;
      height: 2.25rem;
    }
  }

  &__view {
    &--active {
      background: $clr-secondary-200;
      color: $clr-secondary-50;
    }
  }

  &__year,
  &__month {
    &--active {
      background: $clr-secondary-200;
      color: $clr-secondary-50;
    }
  }

  &__day {
    font-size: 0.85rem;

    &__weekdays {
      font-weight: 600;

      &__item {
        width: 2.25rem;
        height: 2.25rem;
        color: $clr-text-3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__item {
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;

      &--between-range {
        background: lighten($color: $clr-primary, $amount: 7);
        border-radius: 0;
      }

      &--between-range-hover {
        border-radius: 0;
      }

      &--active {
        background: $clr-secondary;
        color: $clr-secondary-50;
      }

      &--disabled {
        opacity: 0.5;
      }
    }
  }

  &__placeholder {
    color: $clr-text-table;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 19px;
  }

  &__time {
    &--inbetween {
      font-weight: 400;
      font-size: 32px;
      line-height: 44px;
      color: $clr-primary;
    }

    &__item {
      margin: 1em 0.5em;
      width: 3.5em;

      &--up,
      &--down {
        color: $clr-text-3;
        font-weight: 900;
        font-size: 15px;
        line-height: 15px;
      }

      &__input {
        border: none;
        padding: 0.2em;
        font-size: 32px;
        color: $clr-primary;
      }
    }
  }
}

/* !KISS DATEPICKER */
