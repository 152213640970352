.recently-added-companies-widget {
  &__content {
    overflow: auto;
  }

  &__companies-list {
    display: flex;
    gap: 16px;

    &-item {
      width: 225px;
      min-width: 225px;
      height: 135px;
      min-height: 135px;
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 20px;
      color: white;
      cursor: pointer;

      &--header {
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;

        &-image-name {
          display: flex;
          align-items: center;
          gap: 8px;
          overflow: hidden;
        }

        &-name {
          font-size: $fs-text-lg;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &--action-menu {
        padding: 0 4px;
        color: #9994c2; // Add to variables
        cursor: pointer;
      }

      &--content {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 4px;
      }

      &--consultant-list {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        font-size: $fs-text-sm;
        line-height: $lh-text-sm;
      }

      &--created-at {
        margin-top: auto;
        &-label {
          font-size: $fs-text-sm;
          font-weight: 300;
        }

        &-value {
          font-size: $fs-text-sm;
          font-weight: 700;
        }
      }
    }
  }
}
