.company-dash-checkins {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;

  .cc-card__header {
    margin-bottom: 24px !important;

    .cc-btn__icon {
      font-size: $fs-text-md;
      line-height: $lh-text-md;
      font-weight: $fw-regular;
      color: $clr-text-labels-5;
      text-decoration: underline;
    }
  }

  .cc-card__content {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: flex-start;

    .company-dash-checkin {
      width: calc(50% - 12px);

      @include media($from: 0, $until: "md") {
        width: 100%;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #e5e1ff;

        &__title {
          display: flex;
          align-items: center;
          gap: 17px;
          color: $clr-text;
          font-size: $fs-text-sm;
          line-height: $lh-text-sm;
          font-weight: $fw-bold;

          i {
            font-size: $fs-text-md !important;
          }

          .company-dash-checkin__header__date {
            i {
              margin-right: 4px;
            }
            display: flex;
            align-items: center;
          }
        }

        &__status {
          display: flex;
          gap: 16px;
        }
      }

      .card-panel__body {
        padding: 6px 12px !important;
        .company-dash-checkin__item-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 8px;

          .company-dash-checkin__item__title {
            color: $clr-text-labels-5;
            font-size: $fs-text-sm;
            line-height: $lh-text-md;
            font-weight: $fw-regular;
          }

          .company-dash-checkin__item {
            color: $clr-text;
            font-size: $fs-text-md;
            line-height: $lh-text-md;
            font-weight: $fw-bold;
          }
        }
      }
    }
  }

  .company-dash-card__empty {
    justify-content: center !important;
    width: 100%;
    &__image {
      margin-bottom: 24px;
    }
  }
}
