.company-onboarding {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  background-color: $bg-white;

  &__header {
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;

    &-company-name {
      font-size: $fs-h2;
      line-height: $lh-h2;
      font-weight: $fw-extra-bold;
      color: $clr-primary;
      margin: 0;
    }

    &-message {
      margin: 0;
      font-size: $fs-h3;
      line-height: $lh-h3;
      font-weight: $fw-regular;
      color: #607188;
    }
  }

  &__form-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 48px;
    width: 100%;
    max-height: 100%;
    overflow: auto;
  }

  &__container {
    width: 100%;
    height: 100vh;
    padding: 81px 231px 0 127px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    position: relative;

    @include media($from: 0, $until: "md") {
      padding: 81px 25px 0 25px;
    }
  }

  &__child {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .company-onboarding__wrapper {
      height: 100%;
      overflow: auto;
    }
  }

  .success {
    overflow: hidden !important;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    gap: 36px;
    margin: 24px 0 32px 0;
    // max-width: 1000px;
    width: 100%;

    .cc-btn__primary {
      margin-left: auto;
    }
    .back-btn {
      border: none;
      background: none;
      color: $clr-primary;
      font-size: $fs-text-md;
      line-height: 32px;
      font-weight: $fw-bold;
      cursor: pointer;
      display: flex;
      span {
        font-size: $fs-h4;
        line-height: $lh-h4;
        margin-right: 4px;
        display: block;
      }
    }

    .steps-wrapper {
      display: flex;
      flex-direction: row;
      gap: 24px;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }
  }

  &__step-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__name {
      margin: 0;
      font-size: $fs-text-md;
      line-height: $lh-text-md;
      font-weight: $fw-regular;
      color: $clr-primary-h2;
      text-transform: uppercase;
    }

    &__title {
      margin: 0 0 16px 0;
      font-size: $fs-h2;
      line-height: $lh-h2;
      font-weight: $fw-bold;
      color: $clr-primary;
    }

    &__description {
      margin: 0;
      font-size: $fs-h4;
      line-height: $lh-h4;
      font-weight: $fw-regular;
      color: $clr-text-labels-4;
    }
  }

  &__form {
    width: 100%;

    overflow: auto;

    &__title {
      margin: 0 0 24px 0;
      font-size: $fs-h3;
      line-height: $lh-h3;
      font-weight: $fw-bold;
      color: $clr-primary;
    }
  }
}
