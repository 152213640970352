@font-face {
  font-family: "Texta";
  src: url("/assets/fonts/texta/TextaLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("/assets/fonts/texta/TextaLightIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("/assets/fonts/texta/TextaRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("/assets/fonts/texta/TextaMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("/assets/fonts/texta/TextaMediumIt.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("/assets/fonts/texta/TextaBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("/assets/fonts/texta/TextaBoldIt.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
