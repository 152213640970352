.files-container {
  margin-top: 20px !important;

  .cc-input-container {
    border-radius: 20px !important;
  }

  .custom-file-upload {
    padding: 17px 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .cc-table {
      width: 100%;

      .kiss-cell {
        &:first-of-type {
          text-transform: capitalize;
          font-weight: $fw-regular;
        }

        &:last-of-type {
          max-width: unset !important;
          justify-content: flex-end !important;
          color: $clr-text-table;
          font-weight: $fw-bold;
          font-size: 12px;
          cursor: pointer;
          gap: 10px;
        }

        &.kiss-column-fileType {
          color: $clr-primary-h2;
          font-size: 20px;
        }
      }

      .kiss-header-cell:last-of-type {
        max-width: unset !important;
      }
    }

    label {
      margin: 0;
    }

    p {
      margin: 0;
      font-size: $fs-text-sm;
      line-height: $lh-text-sm;
      font-weight: $fw-regular;
      color: $clr-text-labels-3;

      label {
        color: $lnk-highlight;
        cursor: pointer;
      }
    }

    position: relative;

    input[type="file"] {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      cursor: pointer;
    }

    img {
      cursor: pointer;
      width: 47.5px;
      height: 50px;
    }
  }

  .custom-loading-bar {
    width: 100px;
    max-width: 100px;
    height: 15px;
    border-radius: 12px;
    background-color: $clr-primary-75;
    position: relative;

    &__progress {
      background-color: $clr-primary;
      width: 0;
      height: 100%;
      border-radius: 12px;
      transition: width 0.3s ease;
    }
    p {
      position: absolute;
      color: $clr-white;
      font-size: $fs-text-xs;
      font-weight: $fw-bold;
      text-align: center;
      top: -2px;
      left: 0;
      right: 0;
    }
  }
}
