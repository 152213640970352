.main-nav-sidebar {
  // ACTIVE NAVIGATION-USER
  .navigation-user-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 10px;
    margin: 0 16px 27px 16px;

    .kiss-user-avatar {
      width: 48px;
      height: 48px;
      flex: 0 0 48px;
      font-size: $fs-h3;
      line-height: $lh-h3;
      letter-spacing: -2px;
      background: $clr-nav-link;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      color: $clr-white;
      margin-left: 12px;

      &__name {
        font-size: $fs-text-sm;
        line-height: $lh-text-sm;
      }

      &__email {
        font-size: $fs-text-xs;
        line-height: $lh-text-xs;
      }
    }
  }

  &.folded {
    // FOLDED NAVIGATION-USER
    .navigation-user-container {
      padding: 12px 0;
      margin: 0 10px 27px 10px;

      .user-info {
        display: none;
      }
    }
  }
}
