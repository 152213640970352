.kiss-search {
  padding: 6px 12px;
  border: 1px solid #ccd1d9;
  border-radius: 200px;
  background-color: white;
  gap: 8px;

  &__icon-btn {
    margin: 0;
    color: #5d597e;
  }
}
