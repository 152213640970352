/*
    Usage:
        form-item-container
            form-label
            form-field-wrapper
            form-info-text.error
*/
.form-item-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;

  .show-hide-pass {
    padding: 0 12px;
    color: $support-icon;
    cursor: pointer;
  }

  .form-field-wrapper {
    display: flex;
    flex-direction: column;
  }

  .form-field-wrapper-rounded {
    border-radius: 30px;

    &.invalid {
      border: 1px solid $support-error !important;
    }
  }

  .form-label {
    font-size: $fs-text-xs;
    line-height: $lh-text-xs;
    color: $clr-text-labels-2;
    margin-left: 12px;
  }

  .form-info-text {
    font-size: $fs-text-xs;
    line-height: $lh-text-xs;
    font-weight: 700;
    position: absolute;

    &.error {
      color: $support-error;
    }
  }

  &--no-margin {
    margin-bottom: 0px;
  }
}
