.company-events-workshop {
  &__container {
    overflow: auto;
    display: flex;
  }

  &__table {
    min-width: 1300px;

    .kiss-column {
      &-name {
        flex: 1 0 230px;
        font-weight: 700 !important;
      }

      &-dueDate {
        flex: 0 0 125px;
      }

      &-createdBy {
        flex: 0 0 200px;
      }

      &-teams,
      &-groups {
        min-width: 150px;
      }

      &-status {
        flex-basis: 105px !important;
        flex: 0 0 110px;
        width: 100%;

        .text-ellipsis {
          max-width: 105px !important;
        }
      }
    }

    .kiss-row {
      &.active-preview {
        outline: 2px solid $clr-secondary;
        outline-offset: -2px;
      }
    }
  }
}
