.add-company-user-flyout {
  .cc-flyout {
    &__form {
      &-control {
        &--role {
          margin-bottom: 32px !important;
        }
      }
    }
  }
}
