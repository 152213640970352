.supporting-document {
  &__table {
    &__icon {
      color: $clr-primary-h2;
      height: auto;
      font-size: 20px;
      margin-right: 12px;
      min-width: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .kiss-row {
      &.active-preview {
        outline: 2px solid #5cc2c2;
        outline-offset: -2px;
      }
    }

    .kiss-column {
      &-size {
        flex-basis: 75px;
      }
    }
  }
}
