.card-panel {
  display: flex;
  flex-direction: column;
  border: 1px solid $clr-border-3;
  border-radius: 20px;
  overflow: hidden;

  &__header {
    padding: 10px 16px;
    background: $bg-highlight-50;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &--secondary {
      background: $clr-secondary-50;
    }

    &--spaced {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__body {
    padding: 8px 16px 16px 16px;
    background: $clr-white;
  }
}
