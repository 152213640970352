.auth-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  height: 100%;
  position: relative;
  background: $bg-white;

  .auth-form {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;

    &__header {
      padding: 18px 0 0 30px;

      img {
        width: 290px;
        height: 100px;
      }
    }

    .form-element {
      width: 100%;
      max-width: 518px;
      display: flex;
      flex-direction: column;
      gap: 62px;
      padding: 125px 0 25px 0;
      align-items: center;
      margin: 0 auto;

      @media (max-width: 1536px) {
        padding-top: 62.5px !important;
      }

      .form-text {
        text-align: center;
        h1,
        h2 {
          margin: 0 0 12px 0;
        }

        h1 {
          color: $clr-primary;
          font-size: $fs-xxl;
          line-height: $lh-xxl;
          font-weight: $fw-black;
        }

        h2 {
          color: $clr-primary-h2;
          font-size: $fs-xl;
          font-weight: $fw-bold;
          line-height: $lh-xl;
        }

        p {
          font-size: $fs-h3;
          font-weight: $fw-medium;
          color: $clr-text-basic;
          line-height: $lh-h3;
          word-break: break-word;
          margin: 0 auto;
        }

        @media (max-width: 1536px) {
          h1 {
            margin: 0;
            font-size: $fs-xl;
            line-height: $lh-xl;
          }
          h2 {
            margin: 0;
            font-size: $fs-h2;
            line-height: $lh-h2;
          }
        }
      }

      .inputs-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 9px;

        .form-item-container {
          width: 100%;
        }
      }

      .action-btn-wrap {
        width: 100%;
        max-height: 2.25rem;
        margin-bottom: 18px;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1536px) {
          margin-top: 40px;
        }
      }
    }
  }

  .auth-image {
    width: 50%;
    height: 100%;
    background: url(#{$base-url}auth-page/auth-image.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.invalid {
  border: 1px solid $support-error !important;
}
