.company-dash-kick-off {
  margin-bottom: 16px;

  &__container {
    height: 100%;
    padding: 20px 12px 20px 20px;
    margin-bottom: 0;

    .cc-card__header {
      padding-right: 8px;
    }
  }

  &__content-wrapper {
    height: 100%;
    max-height: 350px;
    padding-right: 8px;
    overflow: auto;

    &.empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__header {
    background: $clr-sky-blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-info {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    font-weight: $fw-bold;
    color: $clr-text;
  }

  &__header-status-select {
    width: auto;
    display: flex;
    gap: 16px;
  }

  &__date,
  &__time {
    display: flex;
    align-items: center;

    i {
      margin-right: 4px;
    }
  }

  &__title {
    font-size: $fs-text-md;
    line-height: $lh-text-md;
    font-weight: $fw-bold;
    color: $clr-text;
  }

  &__item-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 8px;

    &__title {
      font-size: $fs-text-sm;
      line-height: $lh-text-sm;
      color: $clr-text-table;
    }

    &__text {
      font-size: $fs-text-sm;
      line-height: $lh-text-sm;
      color: $clr-primary-500;
    }
  }

  &__footer {
    margin-top: 0 !important;
    padding-right: 8px;
  }
}
