.company-documents-recently-added-widget {
  margin: 24px 0 16px 0;
  user-select: none;

  &__content {
    overflow: auto;
  }

  &__documents-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    gap: 10px;

    &-item {
      display: flex;
      gap: 16px;
      padding: 8px 12px;
      border-radius: 8px;
      border: 1px solid $clr-border-3;
      color: $clr-primary;

      &.file {
        background-color: $clr-secondary-50;
      }

      &.folder {
        background-color: $bg-highlight-50;
      }

      &--icon-name {
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
      }

      &--name {
        font-size: $fs-text-sm;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &--icon {
        color: $clr-primary-h2;
        height: auto;
        font-size: 16px;
        margin-right: 8px;
      }

      &--action-menu {
        padding: 0 4px;
        color: $clr-text-table; // Add to variables
        cursor: pointer;
      }

      &--clickable {
        cursor: pointer;
      }

      &.active-preview {
        outline: 2px solid #5cc2c2;
        outline-offset: -2px;
      }
    }
  }
}
