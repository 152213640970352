.files-upload-dialog {
  width: 795px;

  .kiss-dialog__content-container {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  .kiss-dialog__content {
    background-color: $clr-white;
    max-width: 795px;
    width: 100%;
    margin: 10px;
    outline: none;
    border-radius: 20px;
    max-height: 90vh; /* Set a maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .files-upload-dialog-inner {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__title {
      font-weight: 800;
      font-size: $fs-h2;
      line-height: $lh-h2;
      color: $clr-primary;
      text-align: center;
      margin-bottom: 24px;
    }

    &__form-content {
      width: 100%;

      .files-container {
        margin-top: 0 !important;
      }

      .form-item-container {
        h3 {
          font-weight: $fw-bold;
          font-size: $fs-h3;
          line-height: $lh-h3;
          color: $clr-primary;
          margin: 12px 0 16px 0;
        }
        .textarea-container {
          border-radius: 20px !important;
          position: relative;

          .icon-placeholder {
            position: absolute;
            top: 4px;
            left: 117px;
            font-size: $fs-text-sm;
            color: $clr-text-table;
          }

          .char-counter {
            position: absolute;
            bottom: 12px;
            right: 12px;
            font-size: $fs-text-xs;
            line-height: $lh-text-xs;
            color: $clr-text-labels-2;
          }

          .cc-input.textarea {
            resize: none;
            height: 96px;
          }
        }

        .kiss-select {
          width: 320px;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 24px;
      margin: 0 32px 32px 0;
    }
  }
}
