.profile-settings-page {
  padding: 32px;

  .profile-settings-title {
    margin: 0 0 24px 0;
    font-size: $fs-h2;
    font-weight: $fw-extra-bold;
    line-height: $lh-h2;
    color: $clr-primary;
  }

  .profile-settings-container {
    display: flex;
    width: 100%;

    .profile-settings-forms {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      width: 100%;

      .form {
        width: 25%;
        min-width: 392px;
        min-height: 344px;
        border: 1px solid $clr-border-3;
        border-radius: 20px;
        padding: 20px;
        background-color: $clr-white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .inputs-wrapper {
          display: flex;
          flex-direction: column;
        }

        .form-title {
          font-size: $fs-h3;
          font-weight: $fw-bold;
          line-height: $lh-h3;
          color: $clr-primary;
          margin: 0 0 24px 0;
        }

        .action-btn-wrap {
          display: flex;
          justify-content: flex-end;
          gap: 20px;
        }
      }
    }
  }
}
