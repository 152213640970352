.company-values {
  .widget-content__body--list-item {
    width: calc(50% - 8px);
    height: fit-content;
    overflow: hidden;

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 5px;
      padding: 4px 16px;
      cursor: pointer;

      &__icon {
        cursor: pointer;
        font-size: $fs-text-lg;
        color: $clr-text-table;
        font-weight: $fw-black;
      }

      &__value {
        width: 100%;

        font-size: $fs-text-lg;
        line-height: $lh-text-lg;
        color: $clr-text;
        font-weight: $fw-bold;
      }

      .edit-icon {
        cursor: pointer;
        font-size: $fs-text-lg;
        color: $clr-text-table;
        font-weight: $fw-light;
      }
    }
    .active {
      border-radius: 20px 20px 0 0;
      background-color: $bg-highlight-50;

      .icon {
        padding-top: 4px;
      }
    }

    &__content {
      width: 100%;
      padding: 8px 16px;
      font-size: $fs-text-md;
      line-height: $lh-text-md;
      color: $clr-text;
      font-weight: $fw-regular;
    }
  }
  .company-dash-card__empty {
    width: 100%;
  }
}
