.page-container {
  padding: 0 32px 32px 32px;
  margin: 0 auto;
  width: 100%;
  max-width: $max-width-breakpoint;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0;

    &__title-select {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__right {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: flex-end;
    }

    &__view {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 120px;
      height: 35px;

      .cc-select-transparent {
        width: 100% !important;
      }
    }

    &__title {
      font-size: $fs-h2;
      line-height: $lh-h2;
      font-weight: $fw-extra-bold;
      color: $clr-primary;
    }

    &__subtitle {
      font-size: $fs-h4;
      line-height: $lh-h4;
      font-weight: $fw-bold;
      color: $clr-text-table;
    }
  }

  &__footer {
    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      margin-top: 24px;
    }
  }
}
