.status-select {
  width: 100%;
  height: 30px;
  font-size: $fs-text-sm;
  font-weight: $fw-regular;
  line-height: $lh-text-sm;
  display: flex;
  align-items: center;
  justify-content: center;

  .kiss-select-container {
    width: 100%;
    height: 100%;
    padding: 2px 10px !important;
  }
}
.status-select__dropdown.cc-select .kiss-select {
  &__dropdown {
    min-width: 150px !important;
  }
}

.status-select {
  color: $clr-text-lt;
  border: 2px solid $clr-text-lt;

  .kiss-select-container {
    background: $clr-border-2;
  }
  .kiss-select__icon {
    color: $clr-text-lt;
  }

  &.PLANNED,
  &.SCHEDULED,
  &.IN_PROGRESS {
    color: $clr-primary;
    border: 2px solid $clr-primary;

    .kiss-select-container {
      background: $clr-primary-50 !important;
    }
    .kiss-select__icon {
      color: $clr-primary !important;
    }
  }

  &.OVERDUE {
    color: $support-error;
    border: 2px solid $support-error;

    .kiss-select-container {
      background: $lnk-highlight-50 !important;
    }
    .kiss-select__icon {
      color: $support-error !important;
    }
  }

  &.NOT_STARTED {
    color: #333333;
    border: 2px solid #333333;

    .kiss-select-container {
      background: #f0f0f0 !important;
    }
    .kiss-select__icon {
      color: #333333 !important;
    }
  }

  &.SCHEDULE_LATER {
    color: $clr-text-lt;
    border: 2px solid $clr-text-lt;

    .kiss-select-container {
      background: $clr-border-2 !important;
    }
    .kiss-select__icon {
      color: $clr-text-lt !important;
    }
  }

  &.COMPLETED {
    color: $clr-secondary-dark;
    border: 2px solid $clr-secondary-dark;
    .kiss-select-container {
      background: $clr-secondary-50;
    }
    .kiss-select__icon {
      color: $clr-secondary-dark !important;
    }
  }
}
