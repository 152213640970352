.kiss-user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 12px;
  user-select: none;

  &__colors {
    background-color: #000000;
    color: #ffffff;
  }
}
