// https://dev.to/nidhishs/5-scss-mixins-to-check-out-2bkn#media-queries

// Custom Breakpoints
// EM IS CONSISTENT ACROSS ALL BROWSERS
$breakpoints: (
  sm: 57.6em,
  md: 76.8em,
  xl: 120em,
  xxl: 160em
);

// Media-Query Mixin
@mixin media($from: false, $until: false, $and: false, $media-type: all) {
  $min-width: 0;
  $max-width: 0;
  $query: "";

  //FROM: this breakpoint (inclusive)
  @if $from {
    @if type-of($from) == number {
      $min-width: $from;
    } @else {
      $min-width: map-get($breakpoints, $from);
    }
  }

  //UNTIL: this breakpoint (exclusive)
  @if $until {
    @if type-of($until) == number {
      $max-width: $until - 0.1em;
    } @else {
      $max-width: map-get($breakpoints, $until) - 0.1em;
    }
  }

  @if $min-width != 0 {
    $query: "#{$query} and (min-width: #{$min-width})";
  }
  @if $max-width != 0 {
    $query: "#{$query} and (max-width: #{$max-width})";
  }
  @if $and {
    $query: "#{$query} and (#{$and})";
  }

  @if ($media-type == "all" and $query != "") {
    $media-type: "";
    $query: str-slice(unquote($query), 6);
  }

  @media #{$media-type + $query} {
    @content;
  }
}

@mixin xs {
  @include media($from: 0, $until: "sm") {
    @content;
  }
}

@mixin sm {
  @include media($from: "sm", $until: "md") {
    @content;
  }
}

@mixin md {
  @include media($from: "md", $until: "xl") {
    @content;
  }
}

@mixin xl {
  @include media($from: "xl", $until: "xxl") {
    @content;
  }
}

@mixin xxl {
  @include media($from: "xxl", $until: 100%) {
    @content;
  }
}
