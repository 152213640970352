.cc-table {
  .kiss-row {
    &--clickable {
      cursor: pointer;
    }

    &.active-preview {
      outline: 2px solid #5cc2c2;
      outline-offset: -2px;
    }
  }
}
