.cc-card {
  border: 1px solid $clr-border-3;
  border-radius: 20px;
  padding: 20px;
  background: $clr-white;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar-thumb {
    background-color: $clr-widget-scrollbar !important;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &__title {
      color: $clr-primary;
      font-size: $fs-h3;
      line-height: $lh-h3;
      font-weight: 700;
    }

    &__pre-icon {
      margin-right: 8px;
    }
  }

  .space-between {
    justify-content: space-between !important;
  }

  &__header--trend {
    gap: 16px;
    flex-wrap: wrap;
    justify-content: flex-start !important;
    .wrapper {
      display: flex;
      gap: 16px;
    }

    &__filters {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 16px;
      flex-wrap: wrap;

      &--item {
        width: 175px;
        border-bottom: 2px solid #e9ebf8;
        padding: 0 12px;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
  }
}
