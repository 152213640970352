.page-breadcrumbs {
  display: flex;
  align-items: center;
  gap: 24px;

  &__item {
    display: flex;
    align-items: center;
    color: $clr-text-table;
    font-size: $fs-h2;
    line-height: $lh-h2;
    font-weight: $fw-bold;
    text-decoration: none;
    opacity: 1;

    > span {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      z-index: 2;
    }

    &__next {
      margin-left: 24px;
      font-size: 24px;
    }

    &.active {
      color: $clr-primary;
    }
  }
}
