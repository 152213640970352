.delete-dialog {
  border: 5px solid $support-error;
  border-radius: 20px;

  .promt-dialog-inner {
    background-image: url("/assets/svg/bg/prompt-bg.svg");

    &__icon {
      background: $lnk-highlight-50;
      color: $support-error;
    }

    &__title {
      color: $clr-text-4;
      span {
        color: $lnk-highlight;
      }
    }

    &__message {
      color: $clr-text-4;

      .text-bold {
        font-weight: $fw-bold;
      }
    }
  }
}
