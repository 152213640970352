.company-values {
  .values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;
    width: 100%;

    &__fields {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 12px;
      margin-top: 24px;
      width: 100%;
      margin-bottom: 12px;

      .value {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        gap: 8px;
      }

      .form-item-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        gap: 10px;
        margin-bottom: 0 !important;

        .cc-input-container {
          width: 100%;
        }
        .value__description {
          width: calc(100% - 23px) !important;
          border-radius: 20px !important;
          textarea {
            resize: none;
            height: 100%;
            min-height: 105px;
            padding-top: 6px;
          }
        }

        i {
          cursor: pointer;
          color: $clr-text-table;
          width: 14px;
          height: 24px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
