.analytics-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tree-map .treemap-label p {
    color: $clr-white !important;
    font-weight: bold;

    strong {
      font-weight: $fw-extra-bold;
      font-size: 22px;
    }
  }

  .tree-map .treemap-val {
    display: none !important;
  }

  .tree-map {
    .cell {
      outline: 5px solid white;
    }
  }

  .tree-map chart {
    transform: translate(0px, 0px) !important;
  }
  .company-dash-card__empty.no-trending-themes {
    margin-bottom: 40px;

    .company-dash-card__empty__image {
      width: 107px;
      height: 100px;
    }
  }
  .common-stats {
    width: 100%;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 24px;

    &__item {
      width: calc(25% - 24px);
      min-width: 270px;
      min-height: 220px;
      height: 220px;
      padding: 20px;

      .cc-card__body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
    }

    .theme {
      height: auto;
      .cc-card__header {
        margin-bottom: 18px;
      }
      .cc-card__body {
        height: 100%;

        p {
          margin: 0 !important;
          font-size: $fs-h1;
          line-height: $lh-h2;
          color: $lnk-highlight;
          font-weight: $fw-bold;
          overflow: hidden;

          width: 100%;
          text-align: center;
        }
      }
    }
    .total-focus-areas .cc-card__body {
      color: #5bc2c2;
      font-size: 60px;
      font-weight: $fw-bold;
      line-height: 40px;
      margin-top: 16px;
      p {
        margin: 0 !important;
      }
    }

    .company {
      .cc-card__body {
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .separator {
          width: 1px;
          height: 57px;
          background-color: $clr-border-3;
          margin: 0;
          border: none;
          margin-top: 5px;
          flex: 0 0 1px;
        }
      }
      .company-name {
        min-width: 182px;
        width: fit-content;
        max-width: 250px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        border: 1px solid $clr-border-3;
        border-radius: 10px;
        padding: 10px;
        margin-top: 8px;
        cursor: pointer;
        margin-right: 20px;

        .logo {
          border-radius: 50%;
          box-shadow: 0px 2.08px 8.3px -1.04px #6552521f;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;

          .image {
            width: 100%;
            height: 100%;
            min-width: 100%;
            min-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .name {
          color: $clr-text;
          font-size: $fs-h3;
          line-height: $lh-h3;
          font-weight: $fw-bold;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: calc(250px - 46px);
        }
      }

      .count {
        color: $clr-primary;
        font-size: $fs-xl;
        line-height: $lh-xl;
        font-weight: $fw-bold;
        margin-top: 8px;
        margin-left: 20px;
      }
    }

    .events {
      .cc-card__body {
        width: 100% !important;
        margin-top: 8px !important;
        height: 100%;
      }

      .events-list {
        width: 100%;
      }
      .event {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #de5997;
        font-size: $fs-h3;
        line-height: $fs-h4;
        font-weight: $fw-bold;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
