.kiss-tooltip {
  cursor: pointer;

  &__icon {
    background: $support-info-2;
    border-radius: 50%;
    color: $clr-primary;
    margin: 0 8px;

    &--sm {
      font-size: $fs-text-sm;
      line-height: $lh-text-xs;
    }
  }

  &__tag {
    border: 1px solid $clr-primary;
    border-radius: 16px;
    background: $support-info-2;
    max-width: 300px;
    min-width: 150px;
    font-size: $fs-text-md;
    line-height: $fs-text-md;
    padding: 10px;
    text-wrap: wrap;
  }
}
