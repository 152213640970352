.analytics-dashboard-overview {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  .cc-card {
    display: flex;
    flex: 1 0 calc(50% - 12px);
    min-width: 300px;

    &:last-of-type {
      background-image: url("/assets/svg/bg/company-focus-edit-form.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 8px) 0;
      background-size: auto calc(100% - 8px);
    }
  }

  &__item {
    display: flex;
    gap: 16px;

    &__title {
      font-size: $fs-h3;
      font-weight: $fw-bold;
      line-height: $lh-h3;
      color: $clr-primary;
      flex: 0 0 auto;
    }

    &__text {
      font-size: $fs-text-lg;
      font-weight: $fw-regular;
      line-height: $lh-h3;
      color: $clr-text-lt;
      word-break: break-word;
    }
  }
}
