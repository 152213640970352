.company-general-settings {
  .cc-card__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    @include media($from: 0, $until: "md") {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .label {
      font-size: $fs-h4;
      line-height: $lh-h4;
      color: $clr-primary;
      font-weight: $fw-bold;
    }

    .status-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      width: fit-content;

      .value {
        color: $clr-primary;
        font-size: $fs-text-md;
        line-height: $lh-text-md;
        font-weight: $fw-bold;
      }
    }

    .logo-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      width: fit-content;
      position: relative;

      .logo {
        height: 60px;
        position: relative;
      }
      .edit-icon {
        display: block;
        opacity: 0;
        visibility: hidden;
      }

      &:hover {
        .edit-icon {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .colors-wrapper {
      display: flex;
      align-items: center;
      gap: 13.5px;
      width: fit-content;

      .color {
        width: 24px;
        flex: 0 0 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
