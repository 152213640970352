.company-image {
  &__img-container {
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
    border-radius: 50%;
    overflow: hidden;
    background: $clr-white;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__text {
    color: $clr-primary-400;
    font-weight: 700;
    font-size: $fs-h3;
    line-height: $lh-h4;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
}
