.edit-user-flyout {
  .cc-flyout {
    &__form-control {
      &--role {
        margin-bottom: 32px;
      }
    }
  }
}

.selected {
  opacity: 70% !important;
}
