.company-team-form {
  display: block;
  margin-bottom: 32px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    font-size: $fs-h4;
    line-height: $lh-h4;
    font-weight: $fw-bold;
    color: $clr-primary;
  }

  &__delete-action {
    font-size: $fs-text-md;
    line-height: $lh-text-md;
    font-weight: 700;
    color: $clr-text-table;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;

    & > * {
      flex: 1 0;
      min-width: 150px;
    }
  }

  &__group-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 16px;

    .card-panel {
      width: calc(50% - 8px);

      &__header {
        app-editable-text-control {
          font-size: $fs-text-lg;
          font-weight: 700;
          line-height: $lh-text-lg;
          color: $clr-primary;
        }
      }
    }

    @include media($from: 0, $until: "md") {
      .card-panel {
        width: 100%;
      }
    }
  }

  &__group-title {
    display: flex;
    align-items: baseline;
    gap: 4px;
    margin-bottom: 8px;

    &__text {
      margin: 0;
      font-size: $fs-h4;
      line-height: $lh-h4;
      font-weight: $fw-bold;
      color: $clr-primary;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__delete {
      cursor: pointer;
      font-size: $fs-text-lg;
      line-height: $lh-text-lg;
      color: $clr-text-table;
    }

    &__list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 6px;

      &__title {
        display: flex;
        align-items: baseline;
        gap: 4px;

        &__text {
          font-size: $fs-text-lg;
          font-weight: 700;
          line-height: $lh-text-lg;
          color: $clr-text-table;
          flex: 0 0;
          text-wrap: nowrap;
        }
      }

      &__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      &__item {
        font-size: $fs-text-md;
        font-weight: 700;
        line-height: $lh-text-lg;
        color: $clr-text;
        margin-left: 8px;

        button {
          font-size: 10px;
          line-height: 10px;
          font-weight: 900;
          cursor: pointer;
        }
      }
    }
  }

  &__footer {
    margin: 8px 13px 0 13px;

    .add-group {
      font-size: $fs-text-sm;
      font-weight: $lh-text-sm;
      color: $clr-text-labels-5;
      cursor: pointer;

      i {
        font-size: 10px;
      }
    }
  }
}
