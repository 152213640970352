/*
    Usage:
        cc-checkbox
*/

.cc-checkbox.kiss-checkbox {
  .kiss-checkbox__checkmark-container {
    outline: 1px solid $clr-text-labels-2;
    width: 14px;
    height: 14px;
    border-radius: 3px;
  }

  .kiss-checkbox__label {
    display: flex;
    align-items: center;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .kiss-checkbox__content {
    margin-left: 6px;
  }

  .kiss-checkbox__checkmark {
    background-color: $clr-secondary-500;

    svg {
      height: 10px;
    }
  }

  &:hover {
    .kiss-checkbox__checkmark-container {
      outline-color: $clr-secondary-300;
    }
  }

  &.checked {
    .kiss-checkbox__checkmark-container {
      outline-color: $clr-secondary-500;
      background-color: $clr-secondary-500;
    }
  }

  &.disabled {
    .kiss-checkbox__checkmark-container {
      outline-color: $clr-secondary-300;
    }

    .kiss-checkbox__label {
      color: $clr-text-labels-3;
    }

    &.checked {
      .kiss-checkbox__checkmark-container {
        background-color: $clr-secondary-300;
      }
    }
  }
}
