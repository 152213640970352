.widget-content {
  width: 100%;
  border: 1px solid $clr-border-3;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $bg-white;
  margin-bottom: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin: 0;
      font-size: $fs-h3;
      line-height: $lh-h3;
      font-weight: $fw-bold;
      color: $clr-primary;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;

    &--list {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      width: 100%;

      &-item {
        display: flex;
        flex-direction: column;
        width: 50%;
        border-radius: 20px !important;
      }
    }
  }
}
