.analytics-dash-focus-areas-themes {
  &__counters {
    margin-bottom: 28px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  &__theme-counter,
  &__focus-area-counter {
    width: 130px;
    text-align: center;

    &--value {
      margin-bottom: 6px;
      font-size: 36px;
      font-weight: 700;
    }
  }

  &__status-counters {
    display: flex;
    justify-content: center;
    gap: 24px;
  }

  &__completed-counter,
  &__overdue-counter,
  &__planned-counter,
  &__in-progress-counter,
  &__schedule-later-counter {
    text-align: center;

    &--value {
      width: 60px;
      height: 80px;
      padding-bottom: 20px;
      margin-bottom: 8px;
      border: 2px solid transparent;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      font-size: 34px;
      font-weight: 700;
    }

    &--label {
      font-size: $fs-text-xs;
      line-height: $lh-text-xs;
    }
  }

  &__completed-counter {
    &--value {
      background-color: #f5fbfb;
      border-color: #2a7171;
    }
  }

  &__overdue-counter {
    &--value {
      background-color: #fae6ef;
      border-color: #ec9fc3;
    }
  }

  &__planned-counter {
    &--value {
      background-color: #e4e2ef;
      border-color: #cecbe8;
    }
  }

  &__in-progress-counter {
    &--value {
      background-color: #f7f8f9;
      border-color: #c0cddb;
    }
  }
  &__schedule-later-counter {
    &--value {
      background: #f0f0f0 !important;
      border-color: #c0cddb;
    }
  }
}
