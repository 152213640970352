.company-list {
  &__list {
    &-table {
      .kiss-header-cell, .kiss-cell {
        &.kiss-column {
          &-teams, &-groups {
            justify-content: center;
            text-align: right;
          }
        }
      }

      .kiss-cell {
        &.kiss-column {
          &-teams {
            .text-ellipsis {
              width: 35px;
            }
          }

          &-groups {
            .text-ellipsis {
              width: 40px;
            }
          }
        }
      }
    }
  }
}