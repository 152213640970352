app-company-documents-info {
  position: sticky;
  top: 16px;
}

.company-documents-info-widget {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    width: 100%;

    &-icon {
      margin-top: 4px;
      flex: 0 0 auto;
    }
    app-editable-text-control {
      flex: 1 1 auto;
      width: 80%;
    }
  }

  &__content {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: auto;
  }

  &__footer {
    margin-top: 24px;
  }

  &__access-list {
    &--title {
      margin-bottom: 4px;
      font-size: $fs-h4;
      font-weight: 700;
      color: $clr-primary;
    }

    &--content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--roles {
      flex: 1 1 auto;
    }
    &--manage-button {
      flex: 0 0 auto;
    }
  }

  &__file-size,
  &__file-format,
  &__created-date,
  &__last-edit,
  &__last-download,
  &__description {
    &--title {
      font-size: $fs-text-md;
      font-weight: 700;
      color: $clr-primary;
    }

    &--content {
      font-size: $fs-text-lg !important;
      color: black !important;
      font-weight: normal !important;
    }
  }
}
