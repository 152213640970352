/*Font awesome*/
@import "@kiss/icons/font-awesome/6.5.1/scss/fontawesome.scss";
@import "@kiss/icons/font-awesome/6.5.1/scss/solid.scss";
@import "@kiss/icons/font-awesome/6.5.1/scss/regular.scss";
@import "@kiss/icons/font-awesome/6.5.1/scss/brands.scss";
@import "@kiss/icons/font-awesome/6.5.1/scss/light.scss";
@import "@kiss/icons/font-awesome/6.5.1/scss/thin.scss";

/* angular-cli file: src/styles.css */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
/* Kiss styles */
@import "ngx-toastr/toastr";
@import "@kiss/styles/main";

/* Main style */
@import "scss/main";

:root {
  --font-family: #{$font-family};
  // --scrollbar-bg: rgba(197, 197, 197, 0.2);
  /*------------THEME-------------*/

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
    border-radius: 34px;
  }
  /*-----------!THEME-------------*/
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-family: $font-family;
  font-weight: 400;
  line-height: $lh-text-md;
  font-size: $fs-text-md;
  scroll-behavior: smooth;
}

/* SCROLLBAR */
