.company-onboarding-sidebar {
  width: 320px;
  background-color: $clr-primary-500 !important;
  background: url("/assets/svg/bg/onboarding-sidebar-bg.svg");
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100%;
  padding: 81px 36px;

  &__title {
    font-size: $fs-h2;
    font-weight: $fw-extra-bold;
    line-height: $lh-h2;
    color: $clr-white;
    margin: 0 0 32px 0;
  }

  &__steps {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: 35px;
    align-items: flex-start;
    padding: 0;
    margin: 0;

    &-step {
      font-size: $fs-text-lg;
      font-weight: $fw-regular;
      line-height: $lh-text-lg;
      color: $clr-white;
      margin: 0;
      position: relative;
      padding-left: 20px;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 10.5px;
        width: 7px;
        height: 7px;
        background-color: $clr-white;
        border-radius: 50%;
        transform: translate(-50%, 0px);
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 15px;
        width: 3px;
        height: 60px;
        background-color: $clr-white;
        transform: translate(-50%, 0px);
      }

      &:last-child::after {
        display: none;
      }

      &.active {
        &::before {
          width: 14px;
          height: 14px;
          top: 7.5px;
        }
      }
    }
  }
}
