.supporting-docs-breadcrumbs {
  display: flex;
  align-items: center;
  gap: 16px;

  &__item {
    display: flex;
    align-items: center;
    color: $clr-primary;
    font-size: $fs-h3;
    line-height: $lh-h3;
    font-weight: $fw-bold;
    text-decoration: none;

    > span {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      z-index: 2;

      &:after {
        content: "";
        position: absolute;
        left: -6px;
        top: -3px;
        bottom: -3px;
        right: -6px;
        background-color: $clr-secondary-50;
        border-radius: 8px;
        z-index: -1;
        display: none;
      }

      &:hover {
        &:after {
          display: block;
        }
      }
    }

    &__next {
      margin-left: 16px;
      font-size: 17px;
    }

    &.active {
      opacity: 1;
    }
  }

  &__description {
    position: relative;
    cursor: pointer;

    &__icon {
      margin-left: 8px;
      font-size: 17px;
    }
  }
}
