.analytics-dash-sessions {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;

  @include media($from: 0, $until: "md") {
    flex-direction: column;
  }

  .analytics-dash-card-sessions {
    flex: 1 0 calc(33% - 12px);
    min-width: 350px;

    &--kick-off {
      background-color: #de59971a;
    }

    &--workshop {
      background-color: #5bc2c21a;
    }

    &--check-in {
      background-color: #9189cb1a;
    }

    .cc-card__header__total {
      color: $clr-primary;
      font-size: $fs-h3;
      line-height: $lh-h4;
      font-weight: $fw-regular;
    }

    .cc-card__content {
      .status-bars {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        margin-top: 32px;

        .status-bar {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
          .bar {
            width: 75px;
            height: 121px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            color: $clr-white;
            font-size: 34px;
            line-height: $lh-text-md;
            font-weight: $fw-bold;
            padding: 32px 22px;
          }

          .status-label {
            font-size: $fs-text-xs;
            line-height: 15px;
            color: $clr-text;
            font-weight: $fw-regular;
          }

          .completed {
            background: #5bc2c2;
          }
          .overdue {
            background: #de5997;
          }

          .scheduled {
            background-color: #9189cb;
          }
        }
      }

      .view-all-btn {
        text-decoration: underline;
        color: $clr-text-labels-5;
        font-size: $fs-text-md;
        line-height: $lh-text-md;
        font-weight: $fw-regular;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 16px;

        span {
          cursor: pointer;
        }
      }
    }
  }
}
