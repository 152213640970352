/*
Usage 

   this.toastr.success("TEST", undefined, {
      positionClass: "toast-custom-position__auth"
    });
*/
.toast-custom-position {
  &__auth {
    bottom: 12px;
    margin: 0 auto;
    right: calc(50% + 12px);

    custom-toast {
      max-width: 100% !important;
      width: 100%;
    }
  }
}
