.company-logo-colors {
  .color-picker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    padding: 0 0 10px 6px;
    &__title {
      margin: 0;
      font-size: $fs-h3;
      line-height: $lh-h3;
      font-weight: $fw-bold;
      color: $clr-primary;
    }
    &__colors {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 18px;

      .color {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
        outline-offset: 3px;
      }
    }
  }
}
