custom-toast {
  display: flex;
  background-color: $clr-white;
  border: 1px solid transparent;
  border-radius: 16px;
  padding: 10px;
  max-width: 350px !important;
  background-image: none !important;
  width: 100%;
  min-width: 320px;
  margin-top: 10px;

  &.toast-success {
    background-color: $support-success-3;
    border-color: $support-success;

    .custom-toast-content {
      &__icon {
        background: $support-success;
        color: $clr-white;
      }

      &__footer {
        &__btn {
          background-color: $support-success;
          color: $clr-white;
        }
      }
    }
  }

  &.toast-error {
    background-color: $support-error-3;
    border-color: $support-error;

    .custom-toast-content {
      &__icon {
        background: $support-error;
        color: $clr-white;
      }

      &__footer {
        &__btn {
          background-color: $support-error;
          color: $clr-white;
        }
      }
    }
  }

  &.toast-warning {
    background-color: $support-warning-3;
    border-color: $support-warning;

    .custom-toast-content {
      &__icon {
        background: $support-warning;
        color: $clr-white;
      }

      &__footer {
        &__btn {
          background-color: $support-warning;
          color: $clr-white;
        }
      }
    }
  }

  &.toast-info {
    background-color: $support-info-2;
    border-color: $clr-primary-300;

    .custom-toast-content {
      &__icon {
        background: $clr-primary-300;
        color: $clr-white;
      }

      &__footer {
        &__btn {
          background-color: $clr-primary-300;
          color: $clr-white;
        }
      }
    }
  }

  .custom-toast-content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: relative;

    &__header {
      display: flex;
      align-items: center;
      font-size: $fs-text-lg;
      line-height: $lh-text-sm;
      color: $clr-text-2;

      .close-btn {
        flex: 0 0 24px;
        height: 24px;
        width: 24px;
        font-size: 14px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        margin-left: auto;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      border-radius: 6px;
      font-size: 12px;
      margin-right: 8px;

      i {
        display: block;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      color: $clr-text-3;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;

      &__btn {
        cursor: pointer;
      }
    }
  }
}
