.kiss-paginator {
  &__counter-container {
    margin: 0 !important;
    gap: 5px;
  }

  &__arrow-left,
  &__arrow-right {
    background: transparent;
    color: $clr-text-lt;
  }

  &__number {
    width: 26px;
    height: 26px;
    border: none;
    border-radius: 5px;
    color: $clr-text;
    background-color: $clr-border-2;
  }

  &__active {
    font-weight: 700;
    background-color: $clr-company-button-highlight;
  }
}
