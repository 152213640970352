.kiss-toggle-switch {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  $width: 2.5em;
  $height: 1.25em;
  $sliderSize: 1em;
  $padding: calc($sliderSize / 8);

  &__container {
    position: relative;
    display: inline-block;
    width: $width;
    height: $height;
  }

  &__checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: $height;

    &:before {
      content: '';
      position: absolute;
      height: $sliderSize;
      width: $sliderSize;
      left: $padding;
      bottom: $padding;
      top: $padding;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  &__checkbox:checked + &__slider {
    &:before {
      $transformWidth: calc($sliderSize + $padding * 2);
      -webkit-transform: translateX($transformWidth);
      -ms-transform: translateX($transformWidth);
      transform: translateX($transformWidth);
    }
  }
}
