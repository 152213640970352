.image-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 24px;

  .image {
    position: absolute;
    cursor: pointer;
    box-shadow: 0px 2.08px 8.3px -1.04px #6552521f;
    border-radius: 50%;
    left: 0;
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: relative;

    .edit-icon {
      cursor: pointer;
      font-size: $fs-text-lg;
      color: $clr-text-table;
      font-weight: $fw-black;
      display: none;
      // position: absolute;
      left: 68px;
      top: 19px;
    }

    .upload-button {
      margin-top: 12px;
    }

    input[type="file"] {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      cursor: pointer;
    }

    .icon,
    .image-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      background-color: $bg-white;
    }

    .icon {
      background: #5cc2c233;
      color: $clr-secondary-500;
    }

    .image-preview {
      position: absolute;
      top: 0;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
.big .image-upload__button .icon,
.big .image-upload__button .image {
  width: 112px !important;
  height: 112px !important;
  font-size: 40px;

  .company-image__img-container {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.small .image-upload__button .icon,
.small .image-upload__button .image {
  width: 60px !important;
  height: 60px !important;
  font-size: $fs-h3;

  .company-image__img-container {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
