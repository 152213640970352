.analytics-dashboard-page {
  display: flex;
  flex-direction: column;

  .analytics-dashboard-item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .analytics-dashboard-inline {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    &__focus {
      flex: 1 0 calc(33% - 12px);
      min-width: 350px;
    }

    &__values {
      flex: 1 0 66%;
      min-width: 350px;
    }
  }

  .custom-breadcrumb {
    .page-breadcrumbs__item > span {
      color: $clr-primary !important;
    }
  }
}
