.cc-table {
  .kiss-table-header {
    position: static;
  }

  kiss-table-body {
    gap: 4px;
  }

  kiss-row {
    width: 100%;
    padding: 10px 12px;
    background-color: white;
    border: 1px solid $clr-border-3;
    border-radius: 200px;
    gap: 25px;
  }

  .kiss-header-cell {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    flex: 1 1 150px;
    min-width: 0;
    color: $clr-text-labels-2;
    .check-in-status {
      max-width: fit-content !important;
    }
    .check-in-id {
      flex: 1 1 50px !important;
    }

    .content-hover-info {
      margin-left: 4px;
    }

    .text-ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.kiss-column {
      &-id {
        flex: 0 0 100px;
      }

      &-status {
        flex-basis: 75px;
        min-width: 75px;

        .text-ellipsis {
          width: 100%;
          max-width: 75px;
          text-align: center;
        }
      }

      &-actions {
        min-width: 0;
        max-width: 24px;
        flex-basis: 24px;
      }
    }
  }

  .check-in-id {
    flex: 1 1 50px !important;
  }
  .check-in-name {
    flex: 1 1 100px !important;
  }

  .kiss-cell {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    flex: 1 1 150px;
    min-width: 0;
    color: $clr-text;

    &.kiss-column {
      &-id {
        flex: 0 0 100px;
      }

      &-name {
        font-weight: 700;
      }

      &:last-of-type {
        min-width: 0;
        max-width: 24px;
        display: flex;
        justify-content: flex-end;
        flex-basis: 24px;
      }

      .check-in-status {
        max-width: fit-content !important;
      }
      .check-in-id {
        flex: 1 1 50px !important;
      }
      .check-in-name {
        flex: 1 1 100px !important;
      }

      &-status {
        flex-basis: 75px;
        min-width: 75px;

        .text-ellipsis {
          width: 100%;
          max-width: 75px;
          text-align: center;
          padding: 2px 12px;
          border-radius: 200px;
          line-height: 20px;
        }

        app-status-select {
          width: 100%;
        }
      }

      &-actions {
        min-width: 0;
        max-width: 24px;
        display: flex;
        justify-content: flex-end;
        flex-basis: 24px;

        .action-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          color: #c6c4d2;
          cursor: pointer;

          &:hover {
            color: $clr-text-table;
          }
        }
      }
    }

    .text-ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .kiss-header-row {
    padding: 10px 12px;
    gap: 25px;
  }
}
