.company-events {
  &__list-container {
    display: flex;
    flex-direction: column;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__links {
      display: flex;
      align-items: center;

      &__item-container {
        padding-right: 12px;
        text-decoration: none;

        &.active {
          .company-events__header__links__item {
            opacity: 1;
            border-bottom: 2px solid $clr-primary;
            margin-bottom: -2px;
          }
        }

        &:not(:last-of-type) {
          border-right: 1px solid $clr-border-3;
        }

        &:not(:first-of-type) {
          padding-left: 12px;
        }
      }

      &__item {
        font-weight: $fw-bold;
        font-size: $fs-h3;
        line-height: $lh-h3;
        opacity: 0.6;
        color: $clr-primary;
        cursor: pointer;
      }
    }

    &__actions {
      display: flex;
      gap: 24px;
    }
  }

  &__footer {
    margin-top: 24px;
  }
}
