.company-focus-edit-docs {
  &__form-row {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 12px;
  }

  &__name-input,
  &__link-input {
    width: 100%;
    margin: 0;

    input {
      width: 100%;
      padding: 6px 12px;
      border: 1px solid $clr-border-3;

      &:focus {
        outline: none;
        border: 1px solid $clr-secondary;
      }
    }
  }

  &__link-input {
    input {
      &::placeholder {
        font-size: $fs-text-md;
        line-height: $lh-text-md;
        font-weight: 400;
      }
    }
  }

  &__remove-icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr-text-labels-5;
    cursor: pointer;
  }

  &__add-row-btn {
    margin-left: 12px;
    color: $clr-text-labels-5;
  }
}
