.analytics-dash-card-upcoming {
  .cc-card__content {
    display: flex;
    gap: 40px;
    margin-top: 8px;

    @include media($from: 0, $until: "md") {
      flex-wrap: wrap;
    }

    .month-title {
      font-size: $fs-h3;
      font-weight: $fw-bold;
      line-height: $fs-h3;
      color: $clr-text;
    }
    .calendar-container {
      @include media($from: 0, $until: "md") {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .calendar-wrapper {
          max-width: 343px;
        }
      }
      .calendar-wrapper {
        background-color: #f7f8f9;
        border-radius: 12px;
        width: 100%;
        min-width: 343px;
      }

      .calendar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 13px 15px;
        padding-top: 19px;
      }

      .arrow-button {
        background: none;
        border: none;
        color: $clr-text-labels-3;
        width: 7px;
        height: 11px;
        cursor: pointer;
      }

      mwl-calendar-month-view {
        .cal-header {
          background-color: #f7f8f9;
          font-size: $fs-text-sm;
          line-height: $lh-text-sm;
          font-weight: $fw-bold;
          color: $clr-text-table;
        }
      }

      .cal-month-view .cal-days {
        border: none;
      }

      .cal-month-view .cal-days .cal-cell-row {
        &:not(:last-child) {
          border-top: 1px solid #e4e3e9;
          border-bottom: none;
        }
      }

      .cal-month-view {
        overflow: hidden;
        border-radius: 0 0 12px 12px;
        .cal-day-badge {
          display: none;
        }
        .cal-day-cell {
          width: 50px;
          height: 50px;
          background-color: #f7f8f9;
          min-height: 50px !important;
          font-size: $fs-text-md;
          font-weight: $fw-bold;
          line-height: $lh-text-md;
          color: $clr-text-table;
          border: none;
          position: relative;

          .cal-cell-top {
            min-height: 50px !important;
            border: 2px solid $clr-white;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;

            .cal-day-number {
              margin: 0 !important;
              float: none !important;
              opacity: 1 !important;
            }
          }
        }

        .cal-day-cell.cal-today .cal-day-number {
          font-size: $fs-text-md !important;
        }

        .cal-cell:hover {
          // Add hover styling if needed
        }
      }

      .cal-month-view .cal-events {
        position: absolute;
        bottom: 1px;
        gap: 2px;
        width: 50px;
      }

      .mwl-calendar-event-workshop {
        width: 100%;
        height: 3px;
        border-radius: 0;
        background-color: #de5997 !important;
        margin: 0;
      }

      .mwl-calendar-event-kick_off {
        width: 100%;
        height: 3px;
        border-radius: 0;
        background-color: $clr-primary-100 !important;
        margin: 0;
      }

      .mwl-calendar-event-check_in {
        width: 100%;
        height: 3px;
        border-radius: 0;
        background-color: #5bc2c2 !important;
        margin: 0;
      }
    }

    .sessions-wrapper {
      height: 350px;
      flex: 1 1 100%;
      overflow: auto;

      .sessions-header {
        position: sticky;
        top: 0;
        background: $clr-white;
        height: 40px;
      }
      .sessions {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        row-gap: 24px;
        column-gap: 16px;
        height: auto;
        max-height: 100%;

        .analytics-dash {
          width: calc(50% - 24px);
          min-width: 315px;

          @include media($from: 0, $until: "md") {
            width: 100%;
          }

          &__header {
            display: flex;
            align-items: center;

            &__title {
              display: flex;
              align-items: center;
              gap: 8px;

              font-size: $fs-text-lg;
              line-height: $lh-text-lg;
              font-weight: $fw-bold;

              i {
                font-size: $fs-text-md !important;
              }

              .analytics-dash__header__date {
                font-weight: $fw-regular;
                font-size: $fs-text-sm;
                line-height: $lh-text-sm;
                white-space: nowrap;
                i {
                  margin-right: 8px;
                }
              }
            }
          }

          .card-panel__body {
            padding: 6px 12px !important;
            .analytics-dash__item-wrapper {
              display: flex;
              align-items: flex-start;
              gap: 8px;
              margin-bottom: 8px;

              .analytics-dash__item__title {
                font-size: $fs-text-md;
                line-height: $lh-text-md;
                font-weight: $fw-regular;
                color: $clr-text-labels-5;
              }

              .analytics-dash__item__text {
                font-size: $fs-text-md;
                line-height: $lh-text-md;
                font-weight: $fw-regular;
                color: $clr-text;
              }
            }
          }
        }

        .analytics-dash--kick-off {
          .analytics-dash__header {
            background-color: #f3f2f9;
            color: #494091;
          }
        }

        .analytics-dash--workshop {
          .analytics-dash__header {
            background-color: #fdf5f9;
            color: #8b1b50;
          }
        }

        .analytics-dash--check-in {
          .analytics-dash__header {
            background-color: #f5fbfb;
            color: #2a7171;
          }
        }
      }

      .no-events-calendar {
        height: 100%;

        img {
          width: 253px;
          height: 140px;
        }
      }
    }
  }
}
