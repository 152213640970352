.company-focus-edit {
  &__body {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @include media($from: 0, $until: "md") {
      flex-direction: column;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 1;
  }

  &__item {
    &--history {
      display: flex;
      flex-direction: column;
      flex: 0 0 400px;

      & > * {
        width: 100%;
        position: sticky;
        top: 20px;
      }
    }

    &--doc-info {
      display: flex;
      flex-direction: column;
      flex: 0 0 400px;

      & > * {
        width: 100%;
        position: sticky;
        top: 20px;
      }
    }
  }
}
