.manage-access-dialog {
  width: 410px;
  .kiss-dialog__content-container {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  .kiss-dialog__content {
    background-color: $clr-white;
    max-width: 410px;
    width: 100%;
    margin: 10px;
    outline: none;
    border-radius: 20px;
    background-image: url("/assets/svg/bg/manage-access-bg.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
  }

  .manage-access-dialog-inner {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__close {
      position: absolute;
      top: 18px;
      right: 28px;
      color: #8391a3;
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 24px;
      }
    }

    &__title {
      font-weight: $fw-bold;
      font-size: $fs-h3;
      line-height: $lh-h3;
      color: $clr-primary;
      text-align: center;
      margin-bottom: 32px;
    }

    &__form-content {
      width: 100%;

      .form-item-container {
        .file-name {
          color: $clr-text;
          font-size: $fs-text-md;
          line-height: $lh-text-md;
          font-weight: $fw-regular;
          margin-bottom: 8px;
          span {
            color: $lnk-highlight;
            font-weight: $fw-bold;
          }
        }
        .kiss-select {
          width: 100%;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      margin: 0 32px 32px 0;
    }
  }
}
