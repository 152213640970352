.kiss-menu-content {
  padding: 0;
  border-radius: 16px;
  background-color: white;
  border: 1px solid $clr-border-3;
  box-shadow: none;
  overflow: hidden;
}

.kiss-menu-item {
  min-width: 200px;
  padding: 4px 16px;
  gap: 10px;
  color: $clr-text;
  outline: none;
  font-size: $fs-text-md;
  line-height: $lh-text-md;

  &:hover {
    background-color: #f0ecf6;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $clr-border-3;
  }

  .action-btn-icon {
    color: $clr-primary;
    min-width: 24px;
    text-align: center;
  }
}
