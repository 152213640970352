.company-theme-focus {
  &__list-container {
    display: flex;
    flex-direction: column;
  }

  &__list {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    overflow: auto;

    .cc-list__search-filters {
      flex-wrap: wrap !important;
    }
  }

  &__header {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__links {
      display: flex;
      align-items: center;

      &__item-container {
        padding-right: 12px;
        text-decoration: none;

        &.active {
          .company-theme-focus__header__links__item {
            opacity: 1;
            border-bottom: 2px solid $clr-primary;
            margin-bottom: -2px;
          }
        }

        &:not(:last-of-type) {
          border-right: 1px solid $clr-border-3;
        }

        &:not(:first-of-type) {
          padding-left: 12px;
        }
      }

      &__item {
        font-weight: $fw-bold;
        font-size: $fs-h3;
        line-height: $lh-h3;
        opacity: 0.6;
        color: $clr-primary;
        cursor: pointer;
      }
    }

    &__actions {
      display: flex;
      gap: 24px;
    }
  }

  &__footer {
    margin-top: 24px;
  }
}

.company-focus-table {
  width: 100%;

  .cc-list__list {
    min-width: 1300px !important;

    .cc-table .kiss-cell.kiss-column-status,
    .cc-table .kiss-header-cell.kiss-column-status {
      flex-basis: 120px !important;
      min-width: 120px !important;
    }
  }

  .progressing-switch {
    margin-left: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;

    &__value {
      color: $clr-primary;
      font-weight: 700;
    }
  }

  .non-progressing {
    opacity: 0.6;
  }
}

.company-theme-focus-table {
  width: 100%;
}

.theme-list__table {
  .kiss-table-body {
    gap: 0px !important;
  }

  .kiss-row--clickable {
    &:not(:first-of-type) {
      margin-top: 4px;
    }
  }
}

.red-dot {
  width: 6px;
  height: 6px;
  background-color: #de5997;
  border-radius: 50%;
  margin-left: 2px;
}

.nested-row {
  border-radius: 0px !important;
  min-height: 0px !important;
  padding: 0 !important;
  width: auto !important;
  margin: 0 24px 0 24px;
  border-top: none !important;
  border-bottom: none !important;

  .expanded-row {
    width: 100%;
  }

  .nested-row-child {
    border-radius: 0px !important;
    border: none;

    border-top: 1px solid $clr-border-3 !important;
    border-bottom: 1px solid $clr-border-3 !important;

    &:nth-child(2n) {
      border-top: none !important ;
    }
  }

  &__table {
    &__header {
      font-weight: $fw-bold !important;
      color: $clr-text-labels-4 !important;
    }
    &__cell {
      font-size: $fs-text-sm !important;
      line-height: $lh-text-sm !important;

      .not-progressing {
        color: $clr-text-table;
        border: 2px solid $clr-text-table;
        background: #eff0f3;
        height: 30px;
        font-size: $fs-text-sm;
        font-weight: $fw-regular;
        line-height: $lh-text-sm;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        border-radius: 25px;
      }

      .progressing {
        color: $support-error;
        border: 2px solid $support-error;
        background: $lnk-highlight-50;
        height: 30px;
        font-size: $fs-text-sm;
        font-weight: $fw-regular;
        line-height: $lh-text-sm;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 10px !important;
        width: 40px;
        border-radius: 25px;
      }
    }
  }

  .kiss-table-body {
    gap: 0px !important;
  }
}
