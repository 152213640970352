/*
Usage:

    single:

    <kiss-select class="cc-select" dropdownClass="cc-select" placeholder="placeholder">
        <kiss-select-option value="1" #optionItem>
            test 1 <kiss-svg-icon *ngIf="optionItem.selected"></kiss-svg-icon>
            // should have a checkmark
        </kiss-select-option>
    </kiss-select>


    multiple:

    <kiss-select multiple class="cc-select" dropdownClass="cc-select" [filter]="false" [toggleAll]="false">
      <kiss-select-option value="1">1</kiss-select-option>
      <kiss-select-option value="2" >2</kiss-select-option>
    </kiss-select>
*/

.cc-select.kiss-select {
  border: 1px solid $clr-border-3;
  border-radius: 30px;
  color: $clr-text;
  overflow: hidden;

  &.kiss-disabled {
    opacity: 0.6;
  }

  .kiss-select-container {
    padding: 6px 12px;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    background: $bg-white;
    user-select: none;
  }
}

.cc-select .kiss-select {
  &__placeholder {
    color: $clr-text-table;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr-text-labels-2;
  }

  &__post-icon {
    margin: 0 0 0 4px;
  }

  &__pre-icon {
    margin: 0 4px 0 0;
  }

  &__dropdown {
    border-radius: 20px;
    box-shadow: $box-shadow-1;
    padding: 8px 0;

    .kiss-checkbox {
      margin-right: 12px;
    }
  }

  &__dropdown-header {
    .kiss-checkbox {
      border-bottom: 2px solid #eceae8;
      margin-bottom: 0px;
      width: 100%;
      padding: 12px 8px 12px 21px !important;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      &__label {
        width: 100%;
      }
      &__content {
        margin-left: 12px !important;
      }
      &--selected {
        background: $clr-secondary-50;
        color: $clr-secondary-dark;
      }

      &:hover:not(&--selected) {
        background: $clr-secondary-50;
        color: initial;
      }
    }
  }

  &__empty-text {
    padding: 6px 20px;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    color: $clr-text-table;
  }

  &__option {
    padding: 6px 20px;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    outline: none;

    &__checkbox {
      @extend .cc-checkbox;
      margin-right: 10px;
    }

    &--selected {
      background: $clr-secondary-50;
      color: $clr-secondary-dark;
    }

    &:hover:not(&--selected) {
      background: $clr-secondary-50;
      color: initial;
    }
  }
}

.cc-select {
  &__subtitle {
    color: $clr-text;
    font-weight: $fw-bold;
    font-size: $fs-text-md;
    line-height: $lh-text-md;
    padding-left: 12px;
  }

  &__option {
    &--hidden {
      display: none !important;
    }

    &--checkbox-hidden {
      .kiss-select__option__checkbox {
        display: none;
      }
    }
  }

  &__empty-text {
    padding: 6px 20px;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    color: $clr-text-table;
  }
}
