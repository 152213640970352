.cc-view-flyout {
  &__title {
    margin-bottom: 12px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    padding: 0 16px;

    &-title {
      font-size: $fs-h3;
      font-weight: $fw-bold;
      line-height: $lh-h3;
      margin-bottom: 8px;
    }

    .detail {
      display: flex;
      align-items: baseline;
      gap: 8px;

      .label {
        width: 90px;
        color: $clr-text-lt;
        font-size: $fs-text-md;
        line-height: $lh-text-md;
        font-weight: $fw-bold;
        min-width: 90px;
      }

      .value {
        color: $clr-text;
        font-size: $fs-text-sm;
        line-height: $lh-text-sm;
        font-weight: $fw-regular;
        font-weight: $fw-bold;

        &-list {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &--md {
      .value {
        font-size: $fs-text-md !important;
        line-height: $lh-text-md !important;
        font-weight: $fw-bold !important;
        color: $clr-text !important;
      }
    }

    &--links {
      align-items: flex-start !important;
      flex-wrap: wrap;

      .links {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;

        .value {
          font-size: $fs-text-xs;
          color: $lnk-highlight;
          line-height: $lh-text-xs;
          font-weight: $fw-bold;
          cursor: pointer;
        }
      }
    }

    &--text-area {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 8px;

      .value {
        font-size: $fs-text-md !important;
        line-height: $lh-text-md !important;
        font-weight: $fw-bold !important;
        color: $clr-text !important;
        word-break: break-all;
      }
    }
  }
}
