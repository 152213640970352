@mixin btn-initial() {
  all: unset;
  border-radius: 25px;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

@mixin btn-basic($clr-primary: transparent, $clr-text: transparent, $clr-hover: false, $clr-disable: false) {
  color: $clr-text;
  background: $clr-primary;

  @if $clr-hover {
    &:hover {
      background: $clr-hover;
    }
  }

  @if $clr-disable {
    &:disabled {
      background: $clr-disable;
    }
  } @else {
    &:disabled {
      opacity: 0.4;
    }
  }
}

@mixin btn-stroked($clr-primary: transparent, $clr-hover: false, $clr-hover-bg: false, $clr-disable: false) {
  color: $clr-primary;
  background: transparent;
  outline: 2px solid $clr-primary;
  outline-offset: -2px;

  @if $clr-hover {
    &:hover {
      background: $clr-hover-bg;
      border-color: $clr-hover;
      color: $clr-hover;
    }
  }

  @if $clr-disable {
    &:disabled {
      cursor: default;
      background: transparent;
      border-color: $clr-disable;
      outline: 2px solid $clr-disable;
      color: $clr-disable;
    }
  }
}

@mixin btn-icon() {
  all: unset;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

@mixin common-radius-btn() {
  &--sm {
    border-radius: 6px;
  }
}

@mixin common-sizes-btn() {
  padding: 6px 12px;
  font-size: $fs-text-sm;
  line-height: $lh-text-sm;
  font-weight: 700;

  &--lg {
    padding: 6px 16px;
    font-size: $fs-text-md;
    line-height: $lh-text-md;
  }

  &--sm {
    padding: 6px 12px;
    font-size: $fs-text-xs;
    line-height: $lh-text-xs;

    &--wide {
      padding: 2px 16px;
      font-size: $fs-text-xs;
      line-height: $lh-text-xs;
    }
  }
}

@mixin common-hover() {
  &__color {
    &--secondary {
      &:hover {
        color: $clr-secondary-300 !important;
      }
    }
  }
}

@mixin common-colors() {
  &--secondary {
    color: $clr-secondary-300 !important;
  }

  &--error {
    color: $support-error;
  }
}

/*
Examples: 
md size is default

cc-btn__primary cc-btn__primary--lg
cc-btn__success cc-btn__success--sm
cc-btn__stroked 
*/
.cc-btn {
  @include btn-initial();
  @include common-sizes-btn();

  &__primary {
    @include btn-basic($clr-secondary-dark, $clr-white, $clr-secondary-300, $clr-secondary-75);
  }

  &__success {
    @include btn-basic($support-success, $clr-white);
  }

  &__warning {
    @include btn-basic($support-warning, $clr-white);
  }

  &__error {
    @include btn-basic($support-error, $clr-white, "", $support-error-50);
  }

  &__gray {
    @include btn-basic($clr-border-2, $clr-text);
  }

  &__company {
    @include btn-basic($clr-company-button, $clr-company-text);
  }

  &__stroked {
    @include btn-stroked($clr-primary, $clr-primary-100, $bg-highlight-75-50, $bg-highlight);
  }

  &__icon {
    @include btn-icon();
  }

  &__hover {
    @include common-hover();
  }

  &__color {
    @include common-colors();
  }

  &__radius {
    @include common-radius-btn();
  }
}

/* 
  INTERCHANGEABLE BUTTONS DEPENDING IF 
  COMPANY COLOR CHANGING IS ENABLED
*/
.use-default-color {
  .cc-btn {
    &__dynamic {
      @include btn-basic($clr-secondary-dark, $clr-white, $clr-secondary-300, $clr-secondary-75);
    }
  }
}

.use-company-color {
  .cc-btn {
    &__dynamic {
      @include btn-basic($clr-company-button, $clr-company-text);
    }
  }
}
