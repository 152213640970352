.company-strategies {
  width: 100%;
  height: 100%;
  padding: 0;

  .strategies {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 24px;
    width: 100%;

    &__fields {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 12px;
      margin-top: 24px;
      width: 100%;
      margin-bottom: 12px;

      .strategy {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        gap: 8px;

        &__title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
      }

      .form-item-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        gap: 10px;
        margin-bottom: 0 !important;

        .cc-input-container {
          width: 100%;
        }

        .strategy__description {
          width: calc(100% - 23px) !important;
          border-radius: 20px !important;
          textarea {
            resize: none;
            height: 100%;
            min-height: 105px;
            padding-top: 6px;
          }
        }

        i {
          cursor: pointer;
          color: $clr-text-table;
          width: 14px;
          height: 24px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
