.cc-flyout {
  width: 100%;
  max-width: 533px;
  height: 100%;
  padding: 42px 32px;
  display: flex;
  flex-direction: column;
  background-color: $bg-gray-3;
  overflow: auto;

  &__header {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: $fs-h2;
    font-weight: 800;
    color: $clr-primary;
  }

  &__close-btn {
    i {
      font-size: $fs-h3;
      color: $clr-text-table;
      cursor: pointer;
    }
  }

  &__content {
    flex: 1 1;
  }

  &__general-controls {
    margin-bottom: 32px;
  }

  &__controls-section {
    margin-bottom: 32px;

    &-title {
      margin-bottom: 16px;
      font-size: $fs-h4;
      font-weight: 700;
      color: $clr-primary;
    }
  }

  &__control-list {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .form-item-container {
      margin: 0 !important;
    }
  }

  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-password-active {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-control {
      &-label {
        &--toggle-value {
          font-weight: 700;
          color: $clr-primary;
        }
      }

      &-input {
        padding: 6px 12px;
        border: 1px solid $clr-border-3;

        &:focus {
          outline: none;
          border: 1px solid $clr-secondary;
        }
      }

      &-toggle {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &-array-item {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .deletable-select {
          display: flex;
          align-items: center;
          gap: 4px;

          .kiss-select {
            width: calc(100% - 14px);
          }

          i {
            width: 30px;
            height: 30px;
            color: $clr-gray-icon;
            cursor: pointer;
            padding: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      &--password {
        .cc-btn__icon {
          cursor: pointer;
        }
      }

      &--add-array-item {
        width: fit-content;
        margin-left: 32px;
        color: $clr-gray-icon;
        cursor: pointer;
      }

      &-inline-inputs {
        display: flex;
        gap: 16px;
        width: 100%;
        max-width: calc(100% - 14px);

        .cc-flyout__form-control {
          width: 50%;
          min-width: 225.5px;
        }
      }
    }
  }

  &__multiple-elements-footer {
    display: flex;
    align-items: center;

    .delete-company-btn {
      padding: 4px;
      display: flex;
      align-items: baseline;
      gap: 10px;
      cursor: pointer;
    }
  }

  &__action-buttons {
    margin-left: auto;
    display: flex;
    gap: 20px;

    .cc-btn {
      box-sizing: border-box;
      width: 90px;
      text-align: center;
    }
  }

  .selected-filters {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-top: 16pxs;

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 18px;
      .cc-link {
        border: none;
        background: none;
        cursor: pointer;
      }
    }

    .filters-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      .tag {
        color: $clr-secondary-dark;
        background: $clr-cyan-tag;
        font-size: $fs-text-sm;
        font-weight: $fw-bold;
        padding: 2px 4px 2px 4px;
        width: auto;
        height: 24px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 5px;
        border: none;
        cursor: pointer;

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
        }
      }
    }
  }

  .form {
    &-item {
      &-container {
        margin-bottom: 12px;
      }
    }
  }

  &__footer {
    display: flex;
    gap: 20px;

    &__delete {
      flex: 0 0 auto;
    }

    &__action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      flex: 1 1;
    }
  }
}
