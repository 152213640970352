$_flyout-panel-width-increment: 64px;

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir="rtl"] .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 998;
}
.cdk-overlay-container:empty {
  display: none;
}
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 998;
}
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 998;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.25);
}
.cdk-overlay-transparent-backdrop {
  transition:
    visibility 1ms linear,
    opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 998;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.flyout-panel-container {
  box-sizing: border-box;
  display: block;
  outline: 0;
  min-width: 533px;
  height: 100vh;
  overflow: auto;
  background-color: $bg-gray-3;
  box-shadow:
    0px 1.2px 3.6px rgba(0, 0, 0, 0.11),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
}

.flyout-panel-container-medium {
  min-width: $_flyout-panel-width-increment * 6;
  max-width: calc(100vw - #{$_flyout-panel-width-increment * 2});
}

.flyout-panel-container-large {
  min-width: $_flyout-panel-width-increment * 8;
  max-width: calc(100vw - #{$_flyout-panel-width-increment * 4});
}

.flyout-panel-container-xlarge {
  min-width: $_flyout-panel-width-increment * 9;
  max-width: calc(100vw - #{$_flyout-panel-width-increment * 6});
}
