/*
This file is used to contain all component styles inside KISS.
*/

/*-----COMMON-----*/

//DEPENDANCIES
@import 'common/kiss-backdrop';
@import 'common/kiss-navigation';
@import 'common/kiss-search';
@import 'common/kiss-checkbox';
@import 'common/kiss-loader';

//STYLES
@import 'common/kiss-navigation-sidebar';
@import 'common/kiss-svg-icon';
@import 'common/kiss-menu';
@import 'common/kiss-breadcrumbs';
@import 'common/kiss-sidebar';
@import 'common/kiss-card';
@import 'common/kiss-user-avatar';
@import 'common/kiss-loading-bar';
@import 'common/kiss-table';
@import 'common/kiss-select';
@import 'common/kiss-splash-screen';
@import 'common/kiss-datepicker';
@import 'common/kiss-toggle-switch';
@import 'common/kiss-sort-tag';
/*-----!COMMON-----*/

/*-----KISS-LAYOUT-----*/
@import 'kiss-layout/kiss-navigation-sidebar';
@import 'kiss-layout/kiss-toolbar';
/*-----!KISS-LAYOUT-----*/

/*-----KISS-DIRECTIVES-----*/
@import 'directives/kiss-tooltip';
/*-----@KISS-DIRECTIVES-----*/
