/*
--------------------------------------------
KISS-THEME-SETUP
-------------------------------------------
*/
html,
body {
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow: hidden;
}

/*
--------------------------------------------
!KISS-THEME-SETUP
-------------------------------------------
*/
