.company-events-check-in-flyout {
  textarea {
    resize: none;
    height: 240px;
    border-radius: 20px !important;
  }

  .cc-flyout__form-control--add-array-item {
    margin-left: 12px !important;
    margin-bottom: 12px;

    .cc-btn__icon {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      font-size: $fs-text-sm;
      line-height: $lh-text-sm;

      i {
        display: flex;
        align-items: center;
        font-size: 10px;
      }
    }
  }

  .link-input {
    margin-bottom: 4px !important;

    .input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;

      input {
        width: calc(100% - 30px);
        color: $support-error;
      }
      i {
        width: 30px;
        height: 30px;
        color: $clr-text-labels-5;
        cursor: pointer;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .kiss-datepicker {
    width: 100%;
    max-height: 34px;
    background: #fff;
    border: 1px solid $clr-border-3;
    color: $clr-text;
    font-size: $fs-text-sm;
    line-height: $lh-text-sm;
    border-radius: 30px;
    height: 32px;

    &::placeholder {
      color: $clr-text-table;
    }

    &-container {
      max-height: 32px;
      min-height: 32px;
      padding: 5px 10px;
    }
  }
}
