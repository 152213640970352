.company-dash-teams {
  margin-bottom: 16px;

  &__title-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__title {
    font-size: $fs-h4;
    line-height: $lh-h4;
    font-weight: $fw-bold;
    color: $clr-primary;
  }

  &__info {
    font-size: $fs-text-md;
    line-height: $lh-h4;
    color: $clr-primary;
    font-weight: $fw-regular;
    margin-left: 16px;
  }

  &__toggle {
    font-size: 18px;
    color: $clr-text-table;
    margin-right: 5px;
  }

  &__edit {
    color: $clr-text-table;
    font-size: $fs-text-lg;
    line-height: $lh-text-lg;
    cursor: pointer;

    i {
      font-weight: $fw-light;
    }
  }

  &__item-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 8px;

    &__title {
      font-size: $fs-text-md;
      line-height: $lh-text-md;
      color: $clr-text-table;
      font-weight: $fw-bold;
    }

    &__user {
      font-size: $fs-text-md;
      line-height: $lh-text-md;
      color: $clr-primary-500;
      font-weight: $fw-bold;
      position: relative;

      &:not(:last-child)::after {
        display: inline-block;
        width: 20px;
        text-align: center;
        content: "•";
      }
    }
  }

  &__group-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: flex-start;
  }

  &__group {
    width: calc(50% - 8px);

    @include media($from: 0, $until: "md") {
      width: 100%;
    }
  }

  .action-btn {
    color: $clr-text-table;
    padding: 6px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
