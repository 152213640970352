.choose-company {
  background: url("/assets/svg/bg/choose-company-bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $bg-white;
  width: 100%;
  min-height: 100%;
  height: auto;

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 21px 46px 0 0;

    &--logo {
      width: 203px;
      height: 70px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__body {
    max-width: 890px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 27px 0;

    &--cta {
      text-align: center;
    }

    &--text {
      h1 {
        font-size: $fs-xl-2;
        line-height: $lh-xl-2;
        color: $clr-primary;
        font-weight: $fw-bold;
        margin: 0 0 32px 0 !important;
      }
      p {
        font-size: $fs-h3;
        line-height: $lh-h4;
        color: $clr-primary;
        margin: 0;

        span {
          font-weight: $fw-bold;
        }
      }
    }

    &--companies {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 64px;
      flex-wrap: wrap;
      margin-bottom: 40px;

      &__item {
        width: 262px;
        height: 278px;
        border-radius: 20px;
        padding: 35.5px 0;
        border: 1px solid $clr-border-3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .image {
          width: 83px;
          height: 84px;
          border-radius: 50%;
          box-shadow: 0px 2.08px 8.3px -1.04px #6552521f;
          display: flex;
          align-items: center;
          justify-content: center;

          .company-image__img-container {
            width: 100%;
            height: 100%;
            min-width: 100%;
            min-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &--text {
          width: 100%;
          text-align: center;

          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
          h3 {
            font-size: $fs-h2;
            line-height: $lh-h2;
            color: $clr-text;
            font-weight: $fw-bold;
            margin: 0;
            text-align: center;
            width: 100%;
          }
          p {
            font-size: $fs-h4;
            line-height: $lh-h4;
            color: $clr-primary;
            font-weight: $fw-regular;
            margin: 0;
          }
        }

        .button {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
          width: fit-content;
        }
      }

      .inactive {
        padding: 36px 20px;
        display: flex;
        flex-direction: column;
        background-image: url("/assets/svg/bg/inactive-company-bubble-bg.svg");
        background-color: #f4f2f9;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;

        .choose-company__body--companies__item--text {
          h3 {
            margin-bottom: 16px;
          }
        }
      }
    }
    &--no-companies {
      margin: 65px 0 80px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
