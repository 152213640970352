.docs-attach-dialog {
  .kiss-dialog__content-container {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  .kiss-dialog__content {
    background-color: $clr-white;
    max-width: 795px;
    width: 100%;
    margin: 10px;
    outline: none;
    border-radius: 20px;
    overflow: hidden;
    max-height: 90vh;
  }

  .docs-attach-dialog-wrapper {
    overflow: auto;
    max-height: 90vh;
  }

  .docs-attach-dialog-inner {
    padding: 32px 32px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__title {
      font-weight: 800;
      font-size: $fs-h2;
      line-height: $lh-h2;
      color: $clr-primary;
      text-align: center;
      margin-bottom: 24px;
    }

    .cc-list {
      width: 100%;
      padding: 32px 0;

      .supporting-document__table-container {
        max-height: 300px;
        overflow: auto;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 24px;
      margin: 15px 0 32px 0;
      width: 100%;
    }

    .cc-table {
      padding-left: 35px;

      .active-preview {
        outline: 2px solid #5cc2c2;
        outline-offset: -2px;
        background-color: $clr-secondary-50;
      }

      .kiss-header-cell.kiss-column-checkbox {
        display: none;
      }
      .kiss-cell.kiss-column-checkbox {
        position: absolute;
        left: 0;

        .cc-filters-section__checkbox.docs-checbox {
          padding-top: 5px !important;
          color: $clr-white;

          .kiss-checkbox__checkmark {
            background-color: $clr-secondary-dark;

            &-container {
              width: 13px;
              height: 13px;
              outline: 2px solid $clr-border-3;
              border-radius: 2px;
            }
          }

          .checked {
            .kiss-checkbox__checkmark-container {
              outline: 2px solid $clr-secondary-dark !important;
            }
          }
          .kiss-checkbox__label {
            display: flex;
            gap: 8px;
            font-size: $fs-text-sm;
            font-weight: $fw-regular;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
