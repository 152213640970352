.kiss-loading-bar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;

  &__open {
    display: block;
  }

  .kiss-loader {
    position: relative;
    width: 100%;
    padding: 1px;
  }

  .kiss-loader-bar {
    content: '';
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    width: 0;
    animation: borealisBar 1500ms linear infinite;
  }

  @keyframes borealisBar {
    0% {
      left: 0%;
      right: 100%;
      width: 0%;
    }
    25% {
      left: 0%;
      right: 50%;
      width: 80%;
    }
    50% {
      left: 0%;
      right: 80%;
      width: 100%;
    }
    75% {
      left: 60%;
      right: 100%;
      width: 80%;
    }
    100% {
      left: 100%;
      right: 0%;
      width: 0%;
    }
  }
}
