.analytics-dash-values-goals {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
  height: 100%;

  .cc-widget {
    &__content {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      overflow: auto;
      justify-content: center;
      max-height: 300px;

      .title {
        color: $clr-primary;
        font-size: $fs-h3;
        line-height: $lh-h3;
        font-weight: $fw-bold;
        margin-bottom: 4px;
      }

      .analytics-dash-values-goals__goals,
      .analytics-dash-values-goals__values {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        .item {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          flex-direction: column;
          cursor: pointer;

          .analytics-dash-values-goals__value,
          .analytics-dash-values-goals__goal {
            &__title {
              width: 100%;
              display: flex;
              gap: 5px;
              padding: 0 24px;
              align-items: center;
              border-radius: 50px;
              min-height: 36px;
            }

            &__value {
              padding: 12px 24px;
              align-items: center;
              border-radius: 20px;
              border: 1px solid $clr-border-3;
              font-size: $fs-text-md;
              color: $clr-primary;
              line-height: $lh-text-md;
              font-weight: $fw-regular;
            }
          }

          .analytics-dash-values-goals__value__title {
            background-color: #de599726;
            border: 1px solid #de599780;
            color: $clr-primary;
            font-size: $fs-text-lg;
            line-height: $lh-text-lg;
            font-weight: $fw-bold;
          }

          .analytics-dash-values-goals__goal__title {
            background-color: #4a409126;
            border: 1px solid #cecbe8;
            color: $clr-primary;
            font-size: $fs-text-lg;
            line-height: $lh-text-lg;
            font-weight: $fw-bold;
          }
        }
      }
    }
  }
}
