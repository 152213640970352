@mixin base__link($clr-primary) {
  color: $clr-primary;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
}

@mixin common-sizes-links() {
  font-size: $fs-text-sm;
  line-height: $lh-text-sm;

  &--lg {
    font-size: $fs-text-md;
    line-height: $lh-text-md;
  }

  &--sm {
    font-size: $fs-text-xs;
    line-height: $lh-text-xs;
  }
}

@mixin common-sizes-weight() {
  font-weight: $fw-bold;

  &--regular {
    font-weight: $fw-regular;
  }
}

.cc-link {
  @include base__link($lnk-highlight-300);
  @include common-sizes-links();
  @include common-sizes-weight();

  &--text {
    @include base__link($clr-text-labels-5);
  }
}
