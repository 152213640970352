.no-documents {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 7%;
  }

  &__wrapper {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 445px;
    gap: 64px;
    width: 50%;
    min-width: 500px;
  }

  &__title {
    font-size: $fs-h3;
    line-height: $lh-h4;
    color: $clr-primary;
    font-weight: $fw-bold;
    margin: 0;
  }
  img {
    width: 100%;
  }
}
