.main-nav-sidebar {
  .navigation-company-container {
    margin: 28px 54px 0 22px;

    .company-select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $clr-white;
      font-size: $fs-text-lg;
      line-height: $lh-text-lg;
      font-weight: 700;
      padding: 8px 6px 16px 6px;
      cursor: pointer;

      &__name {
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__icon {
        margin-left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4px;
        font-size: 10px;
      }
    }
  }

  .navigation-image-container {
    margin: 13px 54px 0 22px;

    .navigation-image {
      &__lg {
        padding: 6px 6px 0 6px;
      }

      &__circle {
        padding: 8px 0 16px 0;
        display: none;
      }
    }
  }

  &.folded {
    .navigation-company-container {
      margin: 28px 0px 0 20.5px;

      .company-select {
        padding: 8px 0 16px 0;

        &__name {
          display: none;
        }

        &__icon {
          margin-left: 6px;
        }
      }
    }

    .navigation-image-container {
      margin: 28px 0px 0 20.5px;

      .navigation-image {
        &__lg {
          display: none;
        }

        &__circle {
          display: block;
        }
      }
    }
  }
}

.company-select-menu {
  border-radius: 12px;
  background: $clr-company-highlight;
  border: none;
  max-width: 244px;
  width: 100%;

  &__auto {
    left: 84px;
    top: 32px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 12px 12px 12px;
    max-height: 250px;
  }

  .info {
    font-size: $fs-text-xs;
    font-weight: 400;
    line-height: $lh-text-xs;
    margin-bottom: 8px;
    color: $clr-white;
    padding: 12px 12px 0 12px;
  }

  .kiss-menu-item {
    padding: 8px;
    border: 1px solid $clr-white;
    border-radius: 8px;
    color: $clr-white;
    margin-bottom: 4px;

    &:hover {
      background: $clr-white;
      color: $clr-text;
    }

    &.active {
      background: $clr-white;
      color: $clr-text;
    }
  }

  &__img-container {
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
    border-radius: 50%;
    overflow: hidden;
    background: $clr-white;
    outline: 1px solid $clr-white;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
