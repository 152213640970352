.kiss-breadcrumbs {
  &__list {
    list-style: none;
    margin: 0;
    text-decoration: none;
    color: inherit;
    display: flex;
    white-space: nowrap;
    overflow: auto;
    padding: 8px 32px;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__link {
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.3px;
    text-decoration: none;
    cursor: pointer;
  }

  &__no-link {
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.3px;
  }

  &__icon {
    margin: 0 16px;
  }
}
