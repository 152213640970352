.export-data-dialog {
  .kiss-dialog__content-container {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  .kiss-dialog__content {
    background-color: $clr-white;
    max-width: 525px;
    width: 100%;
    margin: 10px;
    outline: none;
    border-radius: 20px;
  }

  &-inner {
    padding: 32px 32px 15% 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    background-image: url("/assets/svg/bg/export-data-bg.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;

    &__icon {
      width: 96px;
      height: 96px;
      font-size: 55px;
      font-weight: 700;
      border-radius: 50%;
      background: $clr-primary-50;
      color: $clr-primary;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__title {
      font-weight: 800;
      font-size: $fs-h2;
      line-height: $lh-h2;
      color: $clr-text-4;
      text-align: center;
    }

    &__message {
      color: $clr-text-4;
      font-weight: 400;
      font-size: $fs-text-lg;
      line-height: $lh-text-lg;
      text-align: center;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }
  }
}