.supporting-document {
  &__content {
    display: flex;
    gap: 16px;
    user-select: none;

    @include media($from: 0, $until: "md") {
      flex-direction: column;
    }
  }

  &__list-container {
    align-self: flex-start;
    flex: 1 1;
    width: 100%;
    overflow: auto;
  }

  &__list {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      flex-wrap: wrap;
      gap: 16px;

      .search-input {
        margin-right: 16px;
      }

      .actions {
        white-space: nowrap;

        i {
          width: 13px !important;
          padding-right: 20px !important;
        }
      }
    }

    &__breadcrumb {
      color: $clr-primary;
      font-size: $fs-h3;
      line-height: $lh-h3;
      font-weight: $fw-bold;
      opacity: 0.45;

      &.active {
        opacity: 1;
      }
    }

    &__toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      &__btn {
        color: $clr-text-table;
        opacity: 0.45;
        cursor: pointer;

        &.active {
          opacity: 1;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    margin: 15px 0 0 0;
    position: relative;

    &__count {
      font-size: $fs-text-sm;
      line-height: $lh-text-sm;
      color: $clr-text-lt;
    }

    &__action {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 350px;

    &__text {
      font-weight: $fw-bold;
      font-size: $fs-h1;
      line-height: $lh-h1;
      color: $clr-text-labels-4;
    }
  }

  &__info-container {
    flex: 0 0 400px;
  }
}
