@mixin base__input($clr-border, $clr-text, $clr-placeholder, $clr-focus) {
  border: 1px solid $clr-border;
  border-radius: 30px;

  @if $clr-placeholder {
    &::placeholder {
      color: $clr-placeholder;
    }
  }

  &:focus {
    border-color: $clr-focus;
  }
}

@mixin base__textarea($clr-border, $clr-text, $clr-placeholder, $clr-focus) {
  border: 1px solid $clr-border;
  border-radius: 16px;

  @if $clr-placeholder {
    &::placeholder {
      color: $clr-placeholder;
    }
  }

  &:focus {
    border-color: $clr-focus;
  }
}

@mixin reset__input() {
  min-width: 0;
  outline: none;
  color: $clr-text;
  border: 1px solid transparent;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s !important;
  }
}

@mixin common-validation__input($clr-error) {
  &.ng-dirty.ng-invalid {
    border-color: $clr-error;
  }
}

@mixin common-sizes__input() {
  padding: 0 12px;
  font-size: $fs-text-sm;
  line-height: $lh-text-sm;
  border-radius: 30px;
  height: 30px;

  &--sm {
    height: 25px;
  }
}

@mixin common-sizes__textarea() {
  padding: 10px;
  font-size: $fs-text-sm;
  line-height: $lh-text-sm;
  border-radius: 16px;
  resize: none;
  height: 240px;
}

/*
    Input inside the wrapper. Allows for icon usage
    Validation MUST be done on the wrapper

    Usage: 
    cc-input-container
        cc-icon--pre
        cc-input
        cc-icon--post
*/
.cc-input-container {
  @include base__input($clr-border-3, $clr-text, $clr-text-table, $clr-secondary-300);
  @include common-validation__input($support-error);
  display: flex;
  align-items: center;

  .cc-input {
    @include reset__input();
    @include common-sizes__input();
    flex: 1 1;

    &.disabled {
      background-color: $support-light !important;
      color: $clr-text-labels-3;
    }
  }

  &__transparent {
    display: flex;
    align-items: center;
  }
}

.cc-textarea-container {
  @include base__textarea($clr-border-3, $clr-text, $clr-text-table, $clr-secondary-300);
  @include common-validation__input($support-error);
  display: flex;
  align-items: center;

  .cc-textarea {
    @include reset__input();
    @include common-sizes__textarea();
    flex: 1 1;

    &.disabled {
      background-color: $support-light !important;
      color: $clr-text-labels-3;
    }
  }
}

.cc-input__transparent {
  background: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  word-spacing: inherit;
  font-weight: inherit;
  min-width: 0;
  padding: 0;
  outline: none;
  width: auto;
  display: inline-block;
}
