.editable-text-control {
  &__container {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__text {
    overflow: hidden;
    outline: none;
    min-width: 1px;
    word-break: break-word;
  }

  &__placeholder {
    color: $clr-text-table;
    min-width: 1px;
  }

  &__edit {
    margin: 0 8px;
    cursor: pointer;
    font-size: $fs-text-lg;
    color: $clr-text-table;

    i {
      font-weight: $fw-light;
    }
  }
}
