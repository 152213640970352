.add-company-flyout {
  .cc-flyout {
    &__form {
      &-control {
        &--kick-off {
          margin-bottom: 32px;
        }
      }
    }
  }

  .active-control {
    margin-bottom: 32px;
  }
}