.cc-select-transparent.kiss-select {
  color: $clr-text;
  overflow: hidden;

  &.kiss-disabled {
    opacity: 0.6;
  }

  .kiss-select-container {
    font-size: $fs-text-lg;
    line-height: $lh-text-lg;
    font-weight: $fw-regular;
    user-select: none;
    background: transparent;
  }

  .cc-select__open-icon {
    display: none;
  }

  .cc-select__close-icon {
    display: block;
  }

  &--open {
    .cc-select__open-icon {
      display: block;
    }

    .cc-select__close-icon {
      display: none;
    }
  }
}

.cc-select-transparent .kiss-select {
  &__placeholder {
    color: $clr-text-labels-3;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr-text-labels-3;
    font-size: 12px;
    line-height: 12px;
  }

  &__post-icon {
    margin: 0 0 0 8px;
  }

  &__pre-icon {
    margin: 0 8px 0 0;
  }
}
